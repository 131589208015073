import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { getUserAuthFromLocalStorage, removeUserAuthFromLocalStorage, saveUserAuthToLocalStorage } from './util/userAuthPersistUtil';

import AuthData from './type/AuthData';
import User from './type/User';

export interface ISecurityContext {
    auth: AuthData | undefined;
    user: User | undefined;
    verificationCodeInputVisible: boolean;
    setAuth: (auth: AuthData) => void;
    setUser: (user: User) => void;
    setVerificationCodeInputVisible: (isVisible: boolean) => void;
    resetUserData: () => void;
}

export const SecurityContext = createContext<ISecurityContext | undefined>(undefined);

export const SecurityProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { user, auth } = getUserAuthFromLocalStorage();
    const [authState, setAuthState] = useState<AuthData | undefined>(auth);
    const [userState, setUserState] = useState<User | undefined>(user);
    const [verificationCodeInputVisible, setVerificationCodeInputVisible] = useState<boolean>(false);

    useEffect((): void => {
        if (userState && authState) {
            saveUserAuthToLocalStorage(userState, authState);
        }
    }, [userState, authState]);

    const resetUserData = useCallback((): void => {
        setAuthState(undefined);
        setUserState(undefined);
        setVerificationCodeInputVisible(false);
        removeUserAuthFromLocalStorage();
    }, []);

    const value = useMemo(() => {
        return {
            auth: authState,
            user: userState,
            verificationCodeInputVisible,
            setAuth: setAuthState,
            setUser: setUserState,
            setVerificationCodeInputVisible,
            resetUserData,
        };
    }, [authState, userState, verificationCodeInputVisible, setVerificationCodeInputVisible, resetUserData]);

    return <SecurityContext.Provider value={value}>{children}</SecurityContext.Provider>;
};
