export const usersTableName: string = 'Users';

export const usersTableHeaderNames = {
    firstName: 'First Name',
    lastName: 'Last Name',
    mail: 'E-mail',
    phoneNumber: 'Phone',
    role: 'Role',
    createAt: 'Registered',
    actions: 'Actions',
};
