import { useApi } from '../useApi';
import {  prepareAuthHeaders } from '../requestsHelper';
import { baseUrl, ServiceName } from '../urlHelper';

import AuthData from '../../security/type/AuthData';
import User from '../../security/type/User';
import { CONSTANTS_PATH } from '../constant';


export const useUpdateUserAPI = () => {
    const { apiCall } = useApi();

    const updateUser = async (userId: string, userData: User, auth: AuthData | undefined): Promise<User> => {
        const url: string = `${baseUrl(ServiceName.USERS)}${CONSTANTS_PATH.USER_SERVICE}/${userId}`;

        return await apiCall<User>(
            url,
            {
                method: 'PUT',
                data: userData,
                headers: {
                    ...prepareAuthHeaders(auth),
                },
            },
            'User updated successfully!'
        );
    };

    return { updateUser };
};
