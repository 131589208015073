import { useApi } from '../useApi';
import { saveUserAuthToLocalStorage } from '../../security/util/userAuthPersistUtil';
import { baseUrl, ServiceName } from '../urlHelper';

import { HTTP_CODE } from '../constant';
import { isValidRoleForLogin } from '../../security/util/roleUtil';
import { useNotificationContext } from '../../shared/hook/useNotificationContext';
import { useSecurityContext } from '../../security/hook/useSecurityContext';

const AUTH_URL: string = '/auth';

export const useAuthUserAPI = () => {
    const { apiCall } = useApi();
    const { setNotification } = useNotificationContext();
    const { setVerificationCodeInputVisible } = useSecurityContext();

    const authUser = async (userPhone: string = '', smsCode: string = ''): Promise<any> => {
        const url = `${baseUrl(ServiceName.USERS)}${AUTH_URL}`;

        try {
            const response = await apiCall<any>(url, {
                method: 'POST',
                data: {
                    phone: userPhone,
                    smsCode: smsCode,
                },
            });

            if (!isValidRoleForLogin(response?.user?.role)) {
                setNotification({ type: 'error', message: 'You are not allowed to access this service!' }, 2000);
                setVerificationCodeInputVisible(false);
                return;
            }

            saveUserAuthToLocalStorage(response?.user, response?.auth);
            setVerificationCodeInputVisible(false);

            return response;
        } catch (error: any) {
            if (error?.response?.status === HTTP_CODE.AUTH_REQUIRED && setVerificationCodeInputVisible) {
                setVerificationCodeInputVisible(true);
                return null;
            } else {
                console.error('[ERROR] Authentication failed:', error);
                throw error;
            }
        }
    };

    return { authUser };
};
