import { useApi } from '../useApi';
import { baseUrl, ServiceName } from '../urlHelper';


const LOGOUT_URL = '/logout';

export const useLogoutUserAPI = () => {
    const { apiCall } = useApi();

    const logoutUser = async (authToken: string = '', phoneNumber: string = ''): Promise<void> => {
        const url = `${baseUrl(ServiceName.USERS)}${LOGOUT_URL}`;

        try {
            await apiCall<void>(url, {
                method: 'POST',
                data: {
                    token: authToken,
                    phone: phoneNumber,
                },
            });
        } catch (error) {
            console.error('[ERROR] Something went wrong during logout. Reason: ', error);
        }
    };

    return { logoutUser };
};
