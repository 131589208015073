import React, { FC, memo, useEffect, useState } from 'react';
import { UserProvider } from './context/userContext';
import { useUserContext } from './hook/useUserContext';
import { modalReducer } from '../../shared/reducer/modalReducer';

import DataTable from '../../common/table/DataTable';
import EditModal from './component/UserEditModal';
import { Pagination, Segment } from 'semantic-ui-react';

import { TableAction } from '../../common/table/type/TableAction';
import { usersTableHeaderNames, usersTableName } from './util/dataTableUtil';


interface IUserPage extends Partial<Record<'a' | 'b', string>> {}

const UsersPage: FC<IUserPage> = () => {
    const { users, getUsers, chooseUserForEdit, deleteExistingUser } = useUserContext();
    const [state, dispatch] = React.useReducer(modalReducer, {
        open: false,
        dimmer: undefined,
    });
    const { open, dimmer } = state;
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize] = useState<number>(50);

    useEffect(() => {
        getUsers(currentPage, pageSize);
    }, [currentPage, pageSize]);

    const onEditActionClick = (userId: string): void => {
        chooseUserForEdit(userId);
        dispatch({ type: 'OPEN_EDIT_MODAL', dimmer: 'blurring' });
    };

    const onDeleteActionClick = (userId: string): void => {
        deleteExistingUser(userId);
    };

    const editModalClose = (): void => {
        chooseUserForEdit();
        dispatch({ type: 'CLOSE_EDIT_MODAL' });
    };

    const rowActions: TableAction<any>[] = [
        {
            label: 'Edit',
            color: 'blue',
            icon: 'edit',
            size: 'mini',
            onClick: onEditActionClick,
        },
        {
            label: 'Delete',
            color: 'red',
            icon: 'user delete',
            size: 'mini',
            onClick: onDeleteActionClick,
        },
    ];

    const handlePageChange = (_event: React.MouseEvent<HTMLAnchorElement>, data: any): void => {
        const newActivePage = Number(data.activePage);

        if (!isNaN(newActivePage)) {
            setCurrentPage(newActivePage - 1);
        }
    };

    return (
        <div className='iauto-admin-page-wrapper'>
            <Segment>
                <DataTable
                    tableCellsData={users?.items || []}
                    tableHeaderNames={usersTableHeaderNames}
                    tableName={usersTableName}
                    actions={rowActions}
                />
                    <Pagination
                        activePage={currentPage + 1}
                        totalPages={users?.totalPages || 0}
                        onPageChange={handlePageChange}
                    />
            </Segment>
            <EditModal editModalClose={editModalClose} open={open} dimmer={dimmer} />
        </div>
    );
};

const UsersProviderHOC: FC<IUserPage> = memo((props: IUserPage) => {
    return (
        <UserProvider>
            <UsersPage {...props} />
        </UserProvider>
    );
});

export default UsersProviderHOC;
