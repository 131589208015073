import { FC } from 'react';
import './App.css';
import { RouterService } from './config/routing/RouterService';
import { initializePersistObject } from './shared/util/localStorageUtil';

initializePersistObject();

const App: FC = () => {
    return <RouterService />;
};

export default App;
