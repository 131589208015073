import { useContext } from 'react';
import { IUserContext, UserContext } from '../context/userContext';

export const useUserContext = (): IUserContext => {
    const value: IUserContext | undefined = useContext(UserContext);

    if (!value) {
        throw new Error('Wrap inside User Context');
    }

    return value;
};
