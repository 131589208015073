import {Pageable} from "../type/Pageable";
import {PaginatedResponse} from "../type/PaginatedResponse";


export const mapResponseToPageable = <T>(response: PaginatedResponse<T>): Pageable<T> => {
    const content: T[] = response?.content || [];

    return {
        items: content,
        empty: response?.empty ?? true,
        first: response?.first ?? true,
        last: response?.last ?? true,
        number: response?.number ?? 0,
        numberOfElements: response?.numberOfElements ?? 0,
        size: response?.size ?? 0,
        sort: response?.sort || [],
        totalElements: response?.totalElements ?? 0,
        totalPages: response?.totalPages ?? 0,
    };
};