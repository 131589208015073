import { useState } from 'react';

export const useInput = (
    initialValue: string
): [
    {
        value: string;
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    },
    (parameter: any) => void,
] => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(e.target.value);
    };

    const resetValue = (): void => {
        setValue(initialValue);
    };

    return [{ value, onChange: handleChange }, resetValue];
};
