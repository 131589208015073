import React, { FC, memo, useCallback, useEffect, useReducer, useState } from 'react';
import { UserProvider } from '../UsersPage/context/userContext';
import { RequestProvider } from './context/requestContext';
import { modalReducer } from '../../shared/reducer/modalReducer';
import { OfferProvider } from './context/offerContext';
import { useOfferContext } from './hook/useOfferContext';
import { useRequestContext } from './hook/useRequestContext';

import DataTable from '../../common/table/DataTable';
import RequestEditModal from '../RequestsPage/component/RequestEditModal';
import { Pagination, Segment } from 'semantic-ui-react';

import { TableAction } from '../../common/table/type/TableAction';
import { prepareTableData, requestsTableHeaderNames, requestsTableName } from './util/dataTableUtil';
import { useCopyLink } from './hook/useCopyLink';

interface IRequestPage {}

const RequestsPage: FC<IRequestPage> = () => {
    const { requests, getRequests } = useRequestContext();
    const { getRequestOffers } = useOfferContext();
    const [requestIdForEdit, setRequestIdForEdit] = useState<string>('');
    const [state, dispatch] = useReducer(modalReducer, {
        open: false,
        dimmer: undefined,
    });
    const {copyLink} = useCopyLink();

    const { open, dimmer } = state;

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize] = useState<number>(50);

    const fetchRequests = useCallback(async () => {
        await getRequests(currentPage, pageSize);
    }, [getRequests, currentPage, pageSize]);

    useEffect(() => {
        fetchRequests();
    }, [fetchRequests]);

    const handlePageChange = (_event: React.MouseEvent<HTMLAnchorElement>, data: any): void => {
        const newActivePage = Number(data.activePage);

        if (!isNaN(newActivePage)) {
            setCurrentPage(newActivePage - 1);
        }
    };

    const onEditActionClick = async (requestId: string): Promise<void> => {
        await getRequestOffers(requestId);
        setRequestIdForEdit(requestId);
        dispatch({ type: 'OPEN_EDIT_MODAL', dimmer: 'blurring' });
    };
    const editModalClose = (): void => {
        dispatch({ type: 'CLOSE_EDIT_MODAL' });
    };

    const rowActions: TableAction<any>[] = [
        {
            label: 'Edit',
            color: 'blue',
            icon: 'edit',
            size: 'mini',
            onClick: onEditActionClick,
        },
        {
            label: 'Link',
            color: 'purple',
            icon: 'copy',
            size: 'mini',
            onClick: copyLink,
        },
    ];

    return (
        <div className='iauto-admin-page-wrapper'>
            <Segment>
                <DataTable
                    tableCellsData={prepareTableData(requests)}
                    tableHeaderNames={requestsTableHeaderNames}
                    tableName={requestsTableName}
                    actions={rowActions}
                />
                {requests && requests.totalPages >=1 &&
                    <Pagination
                        activePage={currentPage + 1}
                        totalPages={requests?.totalPages || 0}
                        onPageChange={handlePageChange}
                        siblingRange={1}
                        boundaryRange={1}
                    />
                }
            </Segment>
            <RequestEditModal
                editModalClose={editModalClose}
                open={open}
                dimmer={dimmer}
                requestId={requestIdForEdit}
            />
        </div>
    );
};

const RequestsProviderHOC: FC<IRequestPage> = memo((props: IRequestPage) => {
    return (
        <UserProvider>
            <RequestProvider>
                <OfferProvider>
                    <RequestsPage {...props} />
                </OfferProvider>
            </RequestProvider>
        </UserProvider>
    );
});

export default RequestsProviderHOC;
