import { useApi } from '../useApi';
import { prepareAuthHeaders } from '../requestsHelper';
import { baseUrl, ServiceName } from '../urlHelper';

import User from '../../security/type/User';
import AuthData from '../../security/type/AuthData';
import { CONSTANTS_PATH } from '../constant';


export const useCreateUserAPI = () => {
    const { apiCall } = useApi();

    const createUser = async (userData: User, auth: AuthData | undefined): Promise<User> => {
        const url: string = `${baseUrl(ServiceName.USERS)}${CONSTANTS_PATH.USER_SERVICE}`;

        return await apiCall<User>(
            url,
            {
                method: 'POST',
                data: userData,
                headers: {
                    ...prepareAuthHeaders(auth),
                },
            },
            `User with phone = ${userData.phoneNumber} created successfully!`
        );
    };

    return { createUser };
};
