import React from "react";
import DataTable from "../../common/table/DataTable";
import {TableAction} from "../../common/table/type/TableAction";


const StorePage: React.FC = () => {
    const tableName: string = 'Store';
    const tableCellsData = [
        {id: '1', name: 'Test Product 1', supplier: 'Man', code: 'MW123244'},
        {id: '2', name: 'Test Product 2', supplier: 'Bosch', code: 'BO87654'},
    ];
    const tableHeaderNames = {
        name: 'Name',
        supplier: 'Supplier',
        code: 'Code',
        actions: 'Actions',
    };
    const rowActions: TableAction<any>[] = [];

    return (
        <div className='iauto-admin-page-wrapper'>
            <DataTable
                tableCellsData={tableCellsData}
                tableHeaderNames={tableHeaderNames}
                tableName={tableName}
                actions={rowActions}
            />
        </div>
    );
};

export default StorePage;