import { useApi } from '../useApi';
import { prepareAuthHeaders } from '../requestsHelper';
import { baseUrl, ServiceName } from '../urlHelper';

import { CONSTANTS_PATH } from '../constant';
import { ChatRequest } from '../../type/request/ChatRequest';


export const useCreateRequestAPI = () => {
    const { apiCall } = useApi();

    const createRequestAPI = async (auth: any, user: any, requestData: ChatRequest): Promise<any> => {
        const url: string = `${baseUrl(ServiceName.REQUESTS)}${CONSTANTS_PATH.REQUEST_SERVICE}`;

        return await apiCall(
            url,
            {
                method: 'POST',
                data: {...requestData, userId: user.id},
                headers: {
                    ...prepareAuthHeaders(auth),
                },
            },
            'Request created successfully!'
        );
    };

    return { createRequestAPI };
};
