import React, {FC, memo} from "react";
import {Header, Image, Message, Table} from "semantic-ui-react";

type OfferDataTableProps = {
    tableName: string,
    tableHeaderNames: object,
    tableCellsData: any,
};

const OfferDataTable: FC<OfferDataTableProps> = (props: OfferDataTableProps) => {
    const {
        tableName,
        tableHeaderNames,
        tableCellsData,
    } = props;

    const getTableCellItems = (cellData: any): React.JSX.Element[] => {
        const rowData: any = [];
        Object.keys(tableHeaderNames).forEach((headerName: string, index: number) => {
            const cell = cellData[headerName];

            if (headerName === 'images') {
                const firstImage = cell?.[0]?.image || '';
                rowData.push(
                    <Table.Cell key={index}>
                        {firstImage ? (
                            <Image
                                width={80}
                                src={firstImage}
                                alt="Product Image"
                                fallback="https://via.placeholder.com/50"
                            />
                        ) : (
                            <Message>No image</Message>
                        )}
                    </Table.Cell>
                );
            }
                // else if (headerName === 'actions') {
                //     rowData.push(
                //         <Table.Cell key={index}>
                //             <Button primary size="small" onClick={() => {}}>
                //                 Edit
                //             </Button>
                //         </Table.Cell>
                //     );
            // }
            else {
                rowData.push(<Table.Cell key={index}>{cell}</Table.Cell>);
            }
        });

        return rowData;
    };

    const tableHeaderItems: React.JSX.Element[] = Object.values(tableHeaderNames)?.map((headerName: string, index: number) => (
        <Table.HeaderCell key={index}>
            {headerName}
        </Table.HeaderCell>
    ));

    const tableRowItems: React.JSX.Element[] = tableCellsData?.map((rowData: object, index: number) => {
            return <Table.Row key={index}>
                {getTableCellItems(rowData)}
            </Table.Row>
        }
    );

    return (
        <div className='iauto-data-table-wrapper'>
            <Header>{tableName}</Header>
            <Table celled selectable>
                <Table.Header>
                    <Table.Row active>
                        {tableHeaderItems}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {tableRowItems}
                </Table.Body>
            </Table>
        </div>
    );
};

export default memo(OfferDataTable);