export enum CONSTANTS_BASE {
    HTTP = 'http://',
    HTTPS = 'https://',
    API = '/api',
    VERSION = '/v1',
    ERROR = 'error',
}

export enum CONSTANTS_PROD {
    DOMAIN = '164.90.208.138:',
}

export enum CONSTANTS_DEV {
    DOMAIN = 'localhost:',
    REQUEST_SERVICE_PORT = '8082',
    USER_SERVICE_PORT = '8083',
    FRONTEND_APP_PORT = '3000',
    FRONTEND_ADMIN_APP_PORT = '3001',
}

export enum CONSTANTS_PATH {
    USER_SERVICE = '/users',
    REQUEST_SERVICE = '/requests',
    OFFERS = '/offers',
}

export enum HTTP_CODE {
    OK = 200,
    NO_CONTENT = 204,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    UNSUPPORTED_MEDIA_TYPE = 415,
    I_M_TEAPOT = 418,
    RETRY = 449,
    AUTH_REQUIRED = 511,
    CREATED = 201,
}
