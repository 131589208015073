import { useNotificationContext } from '../../../shared/hook/useNotificationContext';

import { isDevelopment } from '../../../shared/util/Utilities';
import { DEV_URL_MAP, PROD_URL_MAP, ServiceName } from '../../../api/urlHelper';


export const useCopyLink = () => {
    const { setNotification } = useNotificationContext();

    const copyLink = (requestId: string): void => {
        try {
            const url: string | undefined = isDevelopment ?
                DEV_URL_MAP.get(ServiceName.IAUTO_FRONTEND_CLIENT) : PROD_URL_MAP.get(ServiceName.IAUTO_FRONTEND_CLIENT);

            const link: string = `${url}/requests/${requestId}`;

            navigator.clipboard.writeText(link).then((): void => {
                setNotification({ type: 'success', message: 'Request URL copied successfully!' }, 2000);
            });
        } catch (error) {
            console.error('Error generating copy link:', error);
        }
    }

    return {copyLink};
};