import UserRole from "../../security/type/enum/UserRole";


export type MenuItem = {
    label: string;
    route: string;
    rolesHaveAccess: string[]
};

export const leftMenuItems: MenuItem[] = [
    {
        label: 'Users',
        route: '/users',
        rolesHaveAccess: [UserRole.ROLE_ADMIN]
    },
    {
        label: 'Requests',
        route: '/requests',
        rolesHaveAccess: [UserRole.ROLE_ADMIN, UserRole.ROLE_OPERATOR_SUPERVISOR, UserRole.ROLE_STOREKEEPER]
    },
    {
        label: 'Store',
        route: '/store',
        rolesHaveAccess: [UserRole.ROLE_ADMIN, UserRole.ROLE_OPERATOR_SUPERVISOR]
    }
];

