import { useState } from 'react';

export const useNumericInput = (
    initialValue: string,
    maxLength = 15
): [
    {
        value: string;
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    },
    (value: string) => void,
] => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const sanitizedValue = e.target.value
            .replace(/\D/g, '')
            .slice(0, maxLength);
        setValue(sanitizedValue);
    };

    const resetValue = (newValue: string = initialValue): void => {
        setValue(newValue);
    };

    return [{ value, onChange: handleChange }, resetValue];
};
