import User from '../type/User';
import AuthData from '../type/AuthData';
import DetailedUser from '../type/DetailedUser';

const APP_PLACEHOLDER = 'iAuto';

export const saveUserAuthToLocalStorage = (
    userData: User | undefined,
    authData: AuthData | undefined
): void => {
    const appDataString = localStorage.getItem(APP_PLACEHOLDER);
    if (appDataString) {
        const appData = JSON.parse(appDataString);
        const updatedPersistObject = {
            ...appData,
            userAuth: {
                auth: authData,
                user: userData,
            },
        };

        localStorage.setItem(
            APP_PLACEHOLDER,
            JSON.stringify(updatedPersistObject)
        );
    }
};

export const getUserAuthFromLocalStorage = (): DetailedUser | any => {
    const appDataString: string | null = localStorage.getItem(APP_PLACEHOLDER);

    if (appDataString) {
        const appData = JSON.parse(appDataString);
        const userAuth = appData.userAuth;

        return {
            user: userAuth?.user,
            auth: userAuth?.auth,
        };
    } else {
        return {
            userData: undefined,
            authData: undefined,
        };
    }
};

export const removeUserAuthFromLocalStorage = (): void => {
    const appDataString: string | null = localStorage.getItem(APP_PLACEHOLDER);

    if (appDataString) {
        const appData = JSON.parse(appDataString);

        if (appData.hasOwnProperty('userAuth')) {
            delete appData.userAuth;
            localStorage.setItem(APP_PLACEHOLDER, JSON.stringify(appData));
        }
    }
};
