/**
 *   maxSizeMB        - image size limit
 *   maxWidthOrHeight - optional resizing for large images
 *   useWebWorker     - use multi-threading for faster compression
 *   maxIteration     - max iteration for better compression
 * @param maxSizeMB
 */
export const getCompressorConfig = (maxSizeMB: number): object => {
    return {
        maxSizeMB,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        maxIteration: 10
    }
};