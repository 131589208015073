/**
 * Formats an ISO 8601 date string to "dd-mm-yyyy, hh:mm:ss"
 * @param isoDateString - The ISO 8601 date string to format
 * @returns The formatted date string, or empty string
 */
export function getFormattedDate(isoDateString: string): string {
    if (!isoDateString || isNaN(Date.parse(isoDateString))) {
        console.error(
            '[ERROR] Could not convert date string to date format. Received string: ' +
                isoDateString
        );

        return '';
    }

    const date: Date = new Date(isoDateString);

    const day: string = String(date.getUTCDate()).padStart(2, '0');
    const month: string = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year: number = date.getUTCFullYear();
    const hours: string = String(date.getUTCHours()).padStart(2, '0');
    const minutes: string = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds: string = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
}
