import {FC, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useSecurityContext} from "../../security/hook/useSecurityContext";
import {useNumericInput} from "../../shared/hook/useNumericInput";
import {useAuthUserAPI} from "../../api/auth/useAuthUserAPI";

import {Container, Grid, Image, Segment} from 'semantic-ui-react';
import LoginForm from "./LoginForm";
// @ts-ignore
import DetailedUser from "../../security/type/DetailedUser";
import {countryPhoneCodes} from "../../shared/util/phoneUtil";

import loginImage from '../../static/app_logo.png';
import './login.css';


const LoginPage: FC = () => {
    const navigate = useNavigate();
    const { authUser } = useAuthUserAPI();
    const {setAuth, setUser, verificationCodeInputVisible, setVerificationCodeInputVisible} = useSecurityContext();

    const [selectedPrefix, setSelectedPrefix] = useState("+373");
    const [phoneNumberProps] = useNumericInput("");
    const [verificationCodeProps] = useNumericInput("");

    const handlePrefixChange = (value: string) => {
        setSelectedPrefix(value);
    };

    const handleSubmitPhoneNumber = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        const fullPhoneNumber = `${selectedPrefix}${phoneNumberProps.value}`;
        await authUser(fullPhoneNumber, '', setVerificationCodeInputVisible);
    };

    const handleSubmitCode = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        const fullPhoneNumber = `${selectedPrefix}${phoneNumberProps.value}`;
        const userData: DetailedUser = await authUser(fullPhoneNumber, verificationCodeProps.value);

        setUser(userData?.user);
        setAuth(userData?.auth);

        navigate('/');
    };

    return (
        <div className='iauto-login-page-wrapper'>
            <Container>
                <Grid textAlign="center" style={{height: '100vh'}} verticalAlign="middle">
                    <Grid.Column style={{maxWidth: 450}}>
                        <Segment raised className='iauto-login-segment'>
                            <div className="iauto-login-logo">
                                <Image src={loginImage} centered size="small"/>
                            </div>
                            <LoginForm
                                onSubmit={verificationCodeInputVisible ? handleSubmitCode : handleSubmitPhoneNumber}
                                inputProps={verificationCodeInputVisible ? verificationCodeProps : phoneNumberProps}
                                verificationCodeVisible={verificationCodeInputVisible}
                                countryPhoneCodes={countryPhoneCodes}
                                selectedPrefix={selectedPrefix}
                                onPrefixChange={handlePrefixChange}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        </div>
    );
};

export default LoginPage;
