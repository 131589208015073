import { useContext } from 'react';
import { ISecurityContext, SecurityContext } from '../securityContext';

export const useSecurityContext = () => {
    const value: ISecurityContext | undefined = useContext(SecurityContext);

    if (!value) {
        throw new Error('Wrap component inside SecurityContext');
    }

    return value;
};
