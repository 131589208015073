import { ChangeEvent, FC } from 'react';
import { Button, Form, Input, Segment, TextArea } from 'semantic-ui-react';
import ImageUpload from './ImageUpload';
import { processImageFile } from '../util/image/ImageUtil';

import { OfferPosition } from '../type/entity/OfferPosition';
import { OfferPositionRawImage } from '../type/entity/OfferPositionRawImage';
import { OfferPositionUploadedImage } from '../type/entity/OfferPositionUploadedImage';

interface OfferBuilderProps {
    offerPositions: OfferPosition[];
    setOfferPositions: React.Dispatch<React.SetStateAction<OfferPosition[]>>;
}

const OfferBuilder: FC<OfferBuilderProps> = ({ offerPositions, setOfferPositions }) => {
    const addOfferPosition = () => {
        setOfferPositions([
            ...offerPositions,
            {
                productName: '',
                brand: '',
                description: '',
                price: 0,
                quantity: 1,
                deliveryTime: null,
                notes: '',
                productCode: '',
                supplier: '',
                images: [],
            },
        ]);
    };

    const handleOfferChange = (index: number, field: string, value: any, e?: any): void => {
        const updatedOffers = [...offerPositions];

        if (field === 'price') {
            const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
            const regex = /^[0-9]*\.?[0-9]*$/;

            if (allowedKeys.includes(e?.key) || regex.test(value) || value === '.') {
                updatedOffers[index] = { ...updatedOffers[index], [field]: value };
            } else {
                return;
            }
        } else {
            updatedOffers[index] = { ...updatedOffers[index], [field]: value };
        }

        setOfferPositions(updatedOffers);
    };

    const handleFileChange = async (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (!file) return;

        try {
            const base64String = await processImageFile(file);

            if (base64String) {
                setOfferPositions((prevOffers: OfferPosition[]) =>
                    prevOffers.map((offer: OfferPosition, i: number): OfferPosition => {
                        const offerWithImages = {
                            ...offer,
                            images: [
                                {
                                    name: file.name,
                                    image_base64: base64String,
                                },
                            ],
                        };

                        return i === index ? offerWithImages : offer;
                    })
                );
            }
        } catch (error) {
            console.error('Error processing file:', error);
        }
    };

    const removeOfferPosition = (index: number) => {
        setOfferPositions(offerPositions.filter((_, i) => i !== index));
    };

    const getCurrentImages = (
        images?: (OfferPositionRawImage | OfferPositionUploadedImage)[]
    ): { name: string; image_base64: string }[] => {
        if (!images) return [];

        return images.map((img) => {
            if ('image_base64' in img) {
                return { name: img.name, image_base64: img.image_base64 };
            } else {
                return { name: img.image, image_base64: '' };
            }
        });
    };

    return (
        <>
            {offerPositions.map((offer: OfferPosition, index: number) => (
                <Segment key={index}>
                    <Form.Group widths='equal'>
                        <Form.Field required>
                            <label>Product Name</label>
                            <Input
                                value={offer.productName}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleOfferChange(index, 'productName', e.target.value)}
                                placeholder='Product Name'
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Brand</label>
                            <Input value={offer.brand} onChange={(e: ChangeEvent<HTMLInputElement>) => handleOfferChange(index, 'brand', e.target.value)} placeholder='Brand' />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>Description</label>
                            <Input value={offer.description} onChange={(e: ChangeEvent<HTMLInputElement>) => handleOfferChange(index, 'description', e.target.value)} placeholder='Description' />
                        </Form.Field>

                        <Form.Field required>
                            <label>Price</label>
                            <Input
                                value={offer.price}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleOfferChange(index, 'price', Number(e.target.value), e.nativeEvent)}
                                placeholder='Price'
                                required
                            />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field required>
                            <label>Quantity</label>
                            <Input
                                type='number'
                                value={offer.quantity}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleOfferChange(index, 'quantity', Number(e.target.value))}
                                placeholder='Quantity'
                                min='1'
                                required
                            />
                        </Form.Field>
                        {/*<Form.Field>*/}
                        {/*    <label>Delivery Time</label>*/}
                        {/*</Form.Field>*/}
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>Product Code</label>
                            <Input value={offer.productCode} onChange={(e: ChangeEvent<HTMLInputElement>) => handleOfferChange(index, 'productCode', e.target.value)} placeholder='Product Code' />
                        </Form.Field>

                        <Form.Field>
                            <label>Supplier</label>
                            <Input value={offer.supplier} onChange={(e: ChangeEvent<HTMLInputElement>) => handleOfferChange(index, 'supplier', e.target.value)} placeholder='Supplier' />
                        </Form.Field>
                    </Form.Group>

                    <Form.Field>
                        <label>Notes</label>
                        <TextArea value={offer.notes} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleOfferChange(index, 'notes', e.target.value)} placeholder='Notes' />
                    </Form.Field>
                    <Form.Field>
                        <label>Image</label>
                        <ImageUpload
                            offerPositionIndex={index}
                            images={getCurrentImages(offer?.images) as OfferPositionRawImage[]}
                            handleFileChange={handleFileChange}
                        />
                    </Form.Field>
                    <Button type='button' negative onClick={() => removeOfferPosition(index)} style={{ marginTop: '10px' }}>
                        Delete
                    </Button>
                </Segment>
            ))}

            <Button type='button' onClick={addOfferPosition} primary>
                Add Position
            </Button>
        </>
    );
};

export default OfferBuilder;
