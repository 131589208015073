import imageCompression from 'browser-image-compression';
import {getCompressorConfig} from "./compressorConfig";
import {getValidImageFormats} from "./imageValidationRules";

const DEFAULT_MAX_IMAGE_SIZE: number = 1;

/**
 * Compress an image file to ensure the size is less than or around 1MB.
 * @param file - The image file to compress.
 * @param maxSizeMB - Target size in MB, default is 1MB.
 * @returns {Promise<File>} - The compressed image file.
 */
export const compressImage = async (file: File, maxSizeMB: number = DEFAULT_MAX_IMAGE_SIZE): Promise<File> => {
    try {
        const compressedFile = await imageCompression(
            file,
            getCompressorConfig(maxSizeMB)
        );
        return compressedFile;
    } catch (error) {
        console.error('Image compression failed: ', error);
        throw error;
    }
};

/**
 * Validate image file size and format (jpeg, png, jpg, gif, svg, webp).
 * @param file - The image file to validate.
 * @returns {boolean} - True if the file is valid, otherwise false.
 */
export const validateImageFile = (file: File): boolean => {
    if (!getValidImageFormats().includes(file.type)) {
        console.error('Invalid file type. Only jpeg, png, jpg, gif, svg, and webp are allowed.');
        return false;
    }

    // if (file.size / 1024 / 1024 > DEFAULT_MAX_IMAGE_SIZE) {
    //     console.error(`File is too large. Maximum allowed size is ${DEFAULT_MAX_IMAGE_SIZE}MB.`);
    //     return false;
    // }

    return true;
};

/**
 * Method to convert files to base64.
 * All validations on size and mime-type can be found in InputValidator.
 * This will be called after compressing the image.
 * @param file - The image file to convert to Base64.
 * @returns {Promise<string>} - The Base64 string of the file.
 */
export const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject): void => {
        const reader: FileReader = new FileReader();

        reader.onloadend = () => {
            resolve(reader.result as string);
        };

        reader.onerror = () => {
            reject(reader.error);
        };

        reader.readAsDataURL(file);
    });
};

/**
 * Main method to process the image by validating, compressing and converting to base64.
 * @param file - The image file to process.
 * @returns {Promise<string | null>} - The Base64 string of the processed image or null if validation fails.
 */
export const processImageFile = async (file: File): Promise<string | null> => {
    if (!validateImageFile(file)) {
        return null;
    }

    try {
        const compressedFile: File = await compressImage(file, DEFAULT_MAX_IMAGE_SIZE);
        const base64String: string = await convertFileToBase64(compressedFile);

        return base64String;
    } catch (error) {
        console.error('Error processing image: ', error);
        return null;
    }
};
