import axios, { AxiosInstance } from 'axios';
import AuthData from '../security/type/AuthData';


export const prepareAuthHeaders = (auth?: AuthData): Record<string, string> => {
    const headers: Record<string, string> = {};

    if (auth) {
        headers['Content-Type']= 'application/json';
        headers.phone = auth.phone || '';
        headers.smsCode = auth.smsCode || '';
        headers['iAuto_token'] = auth.token || '';
        headers.Authorization = `${auth.imageAuthData?.tokenType || ''} ${auth.imageAuthData?.accessToken || ''}`;
    }

    return headers;
};

const requestsHelper: AxiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'true',
    },
});

export default requestsHelper;
