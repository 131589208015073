import React, {FC} from "react";
import {Navigate, Outlet} from "react-router-dom";

import usePermission from "../../security/hook/usePermission";


interface PrivateRouteProps {
    key?: number,
    path?: string;
    element?: React.ReactNode;
}

const PrivateRouteWrapper: FC<PrivateRouteProps> = () => {
    const {userIsAuthenticated} = usePermission();

    if (!userIsAuthenticated()) {
         return <Navigate to="/login"/>;
    }

    return <Outlet/>
};

export default PrivateRouteWrapper;