import React from "react";

const MainPage: React.FC = () => {

    return (
        <div>
            <h1>Hello, this is iAuto admin page</h1>
        </div>
    );
};

export default MainPage;