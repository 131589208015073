import {
    createContext,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useGetUserListAPI } from '../../../api/user/useGetUserListAPI';
import { useSecurityContext } from '../../../security/hook/useSecurityContext';
import { useUpdateUserAPI } from '../../../api/user/useUpdateUserAPI';
import { useCreateUserAPI } from '../../../api/user/useCreateUserAPI';
import { useDeleteUserAPI } from '../../../api/user/useDeleteUserAPI';

import User from '../../../security/type/User';
import { Pageable } from '../../../api/type/Pageable';
import { mapResponseToPageable } from '../../../api/util/mapper';
import { PaginatedResponse } from '../../../api/type/PaginatedResponse';


type UserType = Record<string, unknown>;

export interface IUserContext {
    users: Pageable<User> | undefined;
    userToEdit: User;
    getUsers: (page?: number, size?: number) => Promise<void>;
    chooseUserForEdit: (userId?: string) => void;
    getSelectedUser: (id: string) => UserType | undefined;
    createNewUser: (userData: any) => void;
    deleteExistingUser: (userId: string) => Promise<void>;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);

// @ts-ignore
export const UserProvider = ({ children }) => {
    const { auth } = useSecurityContext();
    const { getUserList } = useGetUserListAPI();
    const { createUser } = useCreateUserAPI();
    const { updateUser } = useUpdateUserAPI();
    const { deleteUser } = useDeleteUserAPI();

    const [userToEdit, setUserToEdit] = useState<User | unknown>({});
    const [users, setUsers] = useState<Pageable<User> | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(50);

    /**
     * GET Users with Pagination
     */
    const getUsers = useCallback(async (
        page: number = 0,
        size: number = 50
    ): Promise<void> => {
        try {
            const response: PaginatedResponse<User> = await getUserList(auth, page, size);
            const pageableUsers: Pageable<User> = mapResponseToPageable(response);

            if (!pageableUsers.items || !Array.isArray(pageableUsers.items)) {
                throw new Error('Invalid user list response format');
            }

            setUsers(pageableUsers);
            setCurrentPage(page);
            setPageSize(size);
        } catch (error) {
            console.error('[ERROR] fetching user data:', error);
        }
    }, [auth, getUserList]);

    /**
     * POST Create User
     */
    const createNewUser = useCallback(async (userData: User): Promise<void> => {
        try {
            await createUser(userData, auth);
            await getUsers(currentPage, pageSize);
        } catch (error) {
            console.error('Failed to create new user', error);
        }
    }, [auth, currentPage, pageSize, createUser]);

    /**
     * PUT Update User by ID
     */
    const updateSelectedUser = useCallback(async (userId: string, updatedUserData: User): Promise<void> => {
        try {
            const updatedUser = await updateUser(userId, updatedUserData, auth);

            setUsers(prevUsers => {
                if (!prevUsers) return prevUsers;

                return {
                    ...prevUsers,
                    items: prevUsers.items.map(user => (user.id === userId ? updatedUser : user)),
                };
            });
        } catch (error) {
            console.error('Failed to update user data', error);
        }
    }, [auth, updateUser]);

    /**
     * DELETE User by ID
     */
    const deleteExistingUser = useCallback(async (userId: string): Promise<void> => {
        try {
            await deleteUser(userId, auth);
            await getUsers(currentPage, pageSize);
        } catch (error) {
            console.error('Failed to delete user:', error);
        }
    }, [auth, deleteUser, getUsers, currentPage, pageSize]);

    /**
     * Select User for Edit
     */
    const chooseUserForEdit = useCallback((userId?: string | undefined) => {
        if (!userId) {
            return setUserToEdit({});
        }

        const userToEdit = users?.items.find(({ id }) => id === userId);
        setUserToEdit(userToEdit || {});
    }, [users]);

    /**
     * Get Selected User by ID
     */
    const getSelectedUser = useCallback((queryId: string) => {
        return users?.items.find(({ id }) => id === queryId);
    }, [users]);


    const value = useMemo(() => {
        return {
            users,
            userToEdit,
            getUsers,
            setUsers,
            createNewUser,
            getSelectedUser,
            chooseUserForEdit,
            updateSelectedUser,
            deleteExistingUser
        };
    }, [
        users,
        userToEdit,
        getUsers,
        setUsers,
        createNewUser,
        chooseUserForEdit,
        getSelectedUser,
        updateSelectedUser,
        deleteExistingUser
    ]);

    return (
        // @ts-ignore
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
};
