import {FC, memo, useState} from "react";
import {useOfferContext} from "../hook/useOfferContext";
import {useUpdateOfferStatusAPI} from "../../../api/offer/useUpdateOfferStatusAPI";
import {useSecurityContext} from "../../../security/hook/useSecurityContext";

import {Button, Dropdown, Item, Label, Message, Modal} from "semantic-ui-react";
import OfferDataTable from "./OfferDataTable";

import {Offer} from "../../../type/offer/Offer";
import {OfferPosition} from "../../../type/offer/OfferPosition";
import {OfferStatus} from "../../RequestsCreatePage/type/OfferStatus";


type RequestEditModalProps = {
    requestId: string;
    editModalClose: () => void;
    dimmer: string | undefined;
    open: boolean;
};

const RequestEditModal: FC<RequestEditModalProps> = ({requestId, dimmer, open, editModalClose}) => {
    const {offers, getRequestOffers} = useOfferContext();
    const {auth} = useSecurityContext();
    const {updateOfferStatus} = useUpdateOfferStatusAPI();
    const [updatedStatuses, setUpdatedStatuses] = useState<{ [key: string]: string }>({});

    const offerStatusOptions = Object.values(OfferStatus).map((status) => ({
        key: status,
        text: status,
        value: status,
    }));

    const handleStatusChange = (offerId: string, newStatus: string): void => {
        setUpdatedStatuses((prevStatuses) => ({
            ...prevStatuses,
            [offerId]: newStatus,
        }));
    };

    const getOffersData = () => {
        return offers?.map((offer: Offer, index: number) => {
            const tableHeaderNames = {
                description: 'Description',
                brand: 'Brand',
                price: 'Price',
                quantity: 'Quantity',
                deliveryTime: 'Delivery time',
                images: 'Image',
            };

            const tableCellsData = offer?.offerPositions?.map((position: OfferPosition) => ({
                description: position?.description,
                brand: position?.brand,
                price: position?.price,
                quantity: position?.quantity,
                deliveryTime: position?.deliveryTime,
                images: position?.images,
            }));

            const currentOfferStatus = offer?.status;
            const updatableOfferStatus = updatedStatuses[offer.id] || offer.status;

            return (
                <Item key={offer.id}>
                    <Item.Content>
                        <Item.Header>Offer {index + 1}</Item.Header>
                        <Item.Meta>
                            <Label>{currentOfferStatus}</Label>
                        </Item.Meta>

                        {/* Render the updated OfferDataTable here */}
                        <OfferDataTable
                            tableName={`Offer ${index + 1} Details`}
                            tableHeaderNames={tableHeaderNames}
                            tableCellsData={tableCellsData}
                        />

                        <Item.Description>
                            <Label>Change Status</Label>
                            <Dropdown
                                placeholder='Select Status'
                                fluid
                                selection
                                options={offerStatusOptions}
                                defaultValue={updatableOfferStatus}
                                onChange={(_, {value}) => handleStatusChange(offer.id, value as string)}
                            />
                        </Item.Description>
                    </Item.Content>
                </Item>
            );
        }) || [];
    };

    const modalContent = offers && offers.length > 0 ? getOffersData() : <Message>No offers for this request</Message>;

    const handleSubmit = async (): Promise<void> => {
        try {
            await updateOfferStatus(auth, updatedStatuses);
            await getRequestOffers(requestId);
            editModalClose();
        } catch (error) {
            console.error('Failed to update offer statuses', error);
        }
    };

    return (
        <Modal dimmer={dimmer} open={open} onClose={editModalClose}>
            <Modal.Header>Edit Request</Modal.Header>
            <Modal.Content>
                <Item.Group divided>{modalContent}</Item.Group>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={editModalClose}>Close</Button>
                <Button positive onClick={handleSubmit}>Save</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(RequestEditModal);