import React, {memo} from "react";
import {arrayIsEmpty} from "../../shared/util/arrayUtils";
import {getFormattedDate} from "../../shared/util/dateHelper";
import {makeRandomKey} from "../../shared/util/Utilities";

import {TableAction} from "./type/TableAction";
import {Button, Divider, Header, Icon, Message, Table} from "semantic-ui-react";


type DataTableProps = {
    tableName: string,
    tableHeaderNames: object,
    tableCellsData: object[],
    actions?: TableAction<any>[]
};

const DataTable: React.FC<DataTableProps> = (
    {
        tableName,
        tableHeaderNames,
        tableCellsData,
        actions
    }) => {
    const getTableCellItems = (cellData: any): React.JSX.Element[] => {
        const rowData: any = [];

        Object.keys(tableHeaderNames)?.forEach((headerName: string, index: number) => {
            let cell = cellData[headerName];
            const parsedActions = actions?.map((action: TableAction<any>) => {
                return <Button
                    key={makeRandomKey()}
                    size='small'
                    color={action?.color}
                    onClick={() => action.onClick(cellData.id)}
                >
                    <Icon name={action?.icon}/>
                    {action.label}
                </Button>
            });

            if (headerName === 'createAt') {
                cell = getFormattedDate(cell);
            }
            if (headerName === 'actions') {
                rowData.push(<Table.Cell key={index}>{parsedActions}</Table.Cell>
                );
            } else {
                rowData.push(<Table.Cell key={index}>
                    {headerName === 'firstName' && <Icon name='user'/>}
                    {headerName === 'clientName' && <Icon name='file alternate'/>}
                    {cell}
                </Table.Cell>);
            }
        });

        return rowData;
    };

    const tableHeaderItems: React.JSX.Element[] = Object.values(tableHeaderNames)?.map((headerName: string, index: number) => (
        <Table.HeaderCell key={index}>
            {headerName}
        </Table.HeaderCell>
    ));

    const tableRowItems: React.JSX.Element[] = tableCellsData?.map((rowData: object, index: number) => {
            return <Table.Row key={index}>
                {getTableCellItems(rowData)}
            </Table.Row>
        }
    );

    return (
        <div className='iauto-data-table-wrapper'>
            {
                arrayIsEmpty(tableRowItems) || tableCellsData.length <= 0 ?
                    <Message icon>
                        <Icon name='warning circle'/>
                        <Message.Content>
                            <Message.Header>No Data Found</Message.Header>
                            {`No data was found for: ${tableName}`}
                        </Message.Content>
                    </Message>
                    :
                    <>
                        <Header>{tableName}</Header>
                        <Divider/>
                        <Table celled selectable>
                            <Table.Header>
                                <Table.Row active>
                                    {tableHeaderItems}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {tableRowItems}
                            </Table.Body>
                        </Table>
                    </>
            }
        </div>
    );
};

export default memo(DataTable);