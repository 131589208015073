import React, { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { useSecurityContext } from '../../../security/hook/useSecurityContext';
import { useGetOfferListAPI } from '../../../api/offer/useGetOfferListAPI';

import { Offer } from '../../../type/offer/Offer';

export interface IOfferContext {
    offers: Array<Offer>;
    getRequestOffers: (requestId: string) => void;
}
interface OfferProviderProps {
    children: ReactNode;
}

export const OfferContext: React.Context<any> = createContext<IOfferContext | undefined>(undefined);

export const OfferProvider = ({ children }: OfferProviderProps) => {
    const { auth } = useSecurityContext();
    const { getOfferList } = useGetOfferListAPI();

    const [offers, setOffers] = useState<Array<Offer>>([]);

    /**
     * GET offers by requestId in context
     */
    const getRequestOffers = useCallback(
        async (requestId: string): Promise<void> => {
            let offers: Array<Offer>;

            try {
                offers = await getOfferList(auth, requestId);

                setOffers(offers);
            } catch (error) {
                console.error('Failed to fetch offers ', error);
            }
        },
        [auth]
    );

    const value = useMemo(() => {
        return {
            offers,
            getRequestOffers,
        };
    }, [offers, getRequestOffers]);

    return <OfferContext.Provider value={value}>{children}</OfferContext.Provider>;
};
