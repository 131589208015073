import { useApi } from '../useApi';
import { saveUserAuthToLocalStorage } from '../../security/util/userAuthPersistUtil';
import { baseUrl, ServiceName } from '../urlHelper';

import { HTTP_CODE } from '../constant';


const AUTH_URL: string = '/auth';

export const useAuthUserAPI = () => {
    const { apiCall } = useApi();

    const authUser = async (userPhone: string = '', smsCode: string = '', setVerificationCodeInputVisible?: (isVisible: boolean) => void): Promise<any> => {
        const url = `${baseUrl(ServiceName.USERS)}${AUTH_URL}`;

        try {
            const response = await apiCall<any>(url, {
                method: 'POST',
                data: {
                    phone: userPhone,
                    smsCode: smsCode,
                },
            });

            saveUserAuthToLocalStorage(response?.user, response?.auth);

            if (setVerificationCodeInputVisible) {
                setVerificationCodeInputVisible(false);
            }

            return response;
        } catch (error: any) {
            if (error?.response?.status === HTTP_CODE.AUTH_REQUIRED && setVerificationCodeInputVisible) {
                setVerificationCodeInputVisible(true);
                return null;
            } else {
                console.error('[ERROR] Authentication failed:', error);
                throw error;
            }
        }
    };

    return { authUser };
};
