import React from "react";
import usePermission from "../../security/hook/usePermission";
import {useSecurityContext} from "../../security/hook/useSecurityContext";
import {useLogoutUserAPI} from "../../api/auth/useLogoutUserAPI";

import {Button, ButtonContent, Icon, Menu} from "semantic-ui-react";
import Logo from '../../static/app_logo.png'


const Header: React.FC = () => {
    // @ts-ignore
    const {user, auth, resetUserData} = useSecurityContext();
    const { logoutUser } = useLogoutUserAPI();
    const {userIsAuthenticated} = usePermission();

    const userName: string = `${user?.firstName} ${user?.lastName}`;
    const onLogoutBtnClick = async (): Promise<void> => {
        await logoutUser(auth?.token, auth?.phone);
        resetUserData();
    }

    const logoutBtn = userIsAuthenticated() &&
        <Button primary animated onClick={onLogoutBtnClick}>
            <ButtonContent visible>Exit</ButtonContent>
            <ButtonContent hidden>
                <Icon name='log out' />
            </ButtonContent>
        </Button>

    return (
        <Menu stackable>
            <Menu.Item>
                <img alt="iauto_logo" src={Logo}/>
            </Menu.Item>

            <Menu.Menu position='right'>
                {/*<Dropdown item text='Language'>*/}
                {/*    <Dropdown.Menu>*/}
                {/*        <Dropdown.Item>EN</Dropdown.Item>*/}
                {/*        <Dropdown.Item>RU</Dropdown.Item>*/}
                {/*        <Dropdown.Item>RO</Dropdown.Item>*/}
                {/*    </Dropdown.Menu>*/}
                {/*</Dropdown>*/}
                <Menu.Item position='right'>
                    <h4>{userName}</h4>
                </Menu.Item>
                <Menu.Item>
                    {logoutBtn}
                </Menu.Item>
            </Menu.Menu>
        </Menu>
    );
};

export default Header;