import React, {FC, memo, useCallback, useReducer, useState} from 'react';
import {formReducer, FormReducerActions, initialState} from "./util/formReducer";
import {useUserContext} from "../UsersPage/hook/useUserContext";
import {UserProvider} from "../UsersPage/context/userContext";

import {Button, Dropdown, Form, FormGroup, Input, Message, Segment} from 'semantic-ui-react';
import {validateForm} from "./util/validateForm";
import {roleOptions} from "./util/roleOptionsConfig";
import {ActionType} from "./type/ActionType";
import {FormState} from "./type/FormState";
import {countryPhoneCodes} from "../../shared/util/phoneUtil";


interface IUserCreatePage {};

const UserCreatePage: FC = () => {
    const { createNewUser } = useUserContext();

    const [formState, dispatch] = useReducer<React.Reducer<FormState, ActionType>>(formReducer, initialState);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedCountryCode, setSelectedCountryCode] = useState<string>('+373');

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // Phone number validation
        if (name === 'phoneNumber') {
            // Check if input is numeric and starts with a digit other than 0
            const numericValue = value.replace(/\D/g, '');
            if (numericValue.length > 0 && numericValue[0] === '0') return; // Block leading zero
            if (numericValue.length > 8) return; // Allow only 8 digits

            dispatch({
                type: FormReducerActions.SET_FIELD,
                field: 'phoneNumber',
                value: numericValue
            });
        } else {
            dispatch({
                type: FormReducerActions.SET_FIELD,
                field: name,
                value
            });
        }
    }, []);

    const handleCountryCodeChange = useCallback((_e: React.SyntheticEvent, { value }: any) => {
        setSelectedCountryCode(value);
    }, []);

    const handleRoleChange = useCallback((_e: React.SyntheticEvent, { value }: any) => {
        dispatch({
            type: FormReducerActions.SET_FIELD,
            field: 'role',
            value
        });
    }, []);

    const handleSubmit = useCallback(async () => {
        const completePhoneNumber = `${selectedCountryCode}${formState.phoneNumber}`;
        const formWithPhoneNumber = { ...formState, phoneNumber: completePhoneNumber };

        const formErrors = validateForm(formWithPhoneNumber);

        if (Object.keys(formErrors).length === 0) {
            await createNewUser(formWithPhoneNumber);
            setFormSubmitted(true);
            setErrors({});

            dispatch({
                type: FormReducerActions.RESET_FORM
            });

            setTimeout(() => {
                setFormSubmitted(false);
            }, 1000);
        } else {
            setErrors(formErrors);
            setFormSubmitted(false);
        }

    }, [formState, selectedCountryCode]);

    return (
        <Segment>
            <h2>Create New User</h2>
            <Form onSubmit={handleSubmit} success={formSubmitted} error={Object.keys(errors).length > 0}>
                <FormGroup widths='equal'>
                    <Form.Field required error={!!errors.phoneNumber}>
                        <label>Phone Number</label>
                        <Input
                            name="phoneNumber"
                            value={formState.phoneNumber}
                            onChange={handleInputChange}
                            placeholder="Enter phone number. Don't enter 0 as first operator number"
                            required
                            label={
                                <Dropdown
                                    defaultValue={selectedCountryCode}
                                    options={countryPhoneCodes}
                                    onChange={handleCountryCodeChange}
                                />
                            }
                            labelPosition='left'
                        />
                        {errors.phoneNumber && <Message error content={errors.phoneNumber} />}
                    </Form.Field>
                    <Form.Field error={!!errors.firstName}>
                        <label>First Name</label>
                        <Input
                            name="firstName"
                            value={formState.firstName}
                            onChange={handleInputChange}
                            placeholder="Enter first name"
                        />
                        {errors.firstName && <Message error content={errors.firstName} />}
                    </Form.Field>
                    <Form.Field error={!!errors.lastName}>
                        <label>Last Name</label>
                        <Input
                            name="lastName"
                            value={formState.lastName}
                            onChange={handleInputChange}
                            placeholder="Enter last name"
                        />
                        {errors.lastName && <Message error content={errors.lastName} />}
                    </Form.Field>
                </FormGroup>
                <FormGroup widths='equal'>
                    <Form.Field>
                        <label>Email</label>
                        <Input
                            name="mail"
                            value={formState.mail}
                            onChange={handleInputChange}
                            placeholder="Enter email (optional)"
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Password</label>
                        <Input
                            name="password"
                            type="password"
                            value={formState.password}
                            onChange={handleInputChange}
                            placeholder="Enter password (optional)"
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Role</label>
                        <Dropdown
                            placeholder="Select role"
                            selection
                            value={formState.role}
                            onChange={handleRoleChange}
                            options={roleOptions}
                        />
                    </Form.Field>
                </FormGroup>
                <Button type="submit" primary>
                    Save User
                </Button>
            </Form>
        </Segment>
    );
};

const UsersCreateProviderHOC: FC<IUserCreatePage> = memo((props:IUserCreatePage) => {
    return (
        <UserProvider>
            <UserCreatePage {...props} />
        </UserProvider>
    );
});

export default UsersCreateProviderHOC;