import { FC, memo, useState } from 'react';
import { useInput } from '../../shared/hook/useInput';

import { UserProvider } from '../UsersPage/context/userContext';
import { RequestProvider } from '../RequestsPage/context/requestContext';
import { OfferProvider } from '../RequestsPage/context/offerContext';
import { CreateRequestProvider } from './context/createRequestContext';
import { useCreateRequestContext } from './hook/useCreateRequestContext';

import { Button, Divider, Form, Header, Input, Segment, TextArea } from 'semantic-ui-react';
import { OfferPosition } from './type/entity/OfferPosition';
import OfferBuilder from './component/OfferBuilder';
import RequestCreationConfirmModal from './component/RequestCreationConfirmModal';

import { RequestCreationType } from './type/enums/RequestCreationType';
import { MessageContentType } from './type/enums/MessageContentType';


interface IRequestCreatePage {}

const RequestCreatePage: FC = () => {
    const { createRequest, createdRequest } = useCreateRequestContext();
    const [userPhonesInput, resetUserPhone] = useInput('');
    const [operatorPhoneInput, resetOperatorPhone] = useInput('');
    const [descriptionInput, resetDescription] = useInput('');
    const [carDocument, setCarDocument] = useState<File | null>(null);
    const [createRequestModalOpened, setCreateRequestModalOpened] = useState(false);
    const [offerPositions, setOfferPositions] = useState<OfferPosition[]>([
        {
            productName: '',
            brand: '',
            description: '',
            price: 0,
            quantity: 1,
            deliveryTime: null,
            notes: '',
            productCode: '',
            supplier: '',
        },
    ]);

    const handleSubmit = async (): Promise<void> => {
        const clientPhones = userPhonesInput.value ? userPhonesInput.value.split(',').map((phone: string) => phone.trim()) : [];

        if (!operatorPhoneInput.value) {
            alert('Operator phone is required!');

            return;
        }

        const newRequest: any = {
            creationType: RequestCreationType.CREATED_BY_ADMIN,
            clientPhones: clientPhones.length > 0 ? clientPhones : [],
            operatorPhone: [operatorPhoneInput.value],
            messages: [
                {
                    messageType: MessageContentType.IMAGE,
                    img: carDocument,
                },
                {
                    messageType: MessageContentType.TEXT,
                    text: descriptionInput.value && descriptionInput.value.length > 0 ? descriptionInput.value : 'Welcome to iAuto. This is your offer',
                },
                {
                    messageType: MessageContentType.OFFER,
                    offer: { offerPositions: offerPositions },
                },
            ],
        };

        await createRequest(newRequest);
        openCreateRequestModal();
        handleClear();
    };

    const handleClear = (): void => {
        resetUserPhone('');
        resetOperatorPhone('');
        resetDescription('');
        setCarDocument(null);
        setOfferPositions([]);
    };

    const openCreateRequestModal = () => {
        setCreateRequestModalOpened(true);
    };

    const closeCreateRequestModal = () => {
        setCreateRequestModalOpened(false);
    };

    return (
        <div className='iauto-admin-page-wrapper'>
            <Segment>
                <Header>New Request</Header>
                <Divider />
                <Form onSubmit={handleSubmit}>
                    <Form.Field>
                        <label>Client Phones (Optional)</label>
                        <Input {...userPhonesInput} placeholder='Enter phone numbers separated by commas' />
                    </Form.Field>
                    <Form.Field required>
                        <label>Operator Phone</label>
                        <Input {...operatorPhoneInput} placeholder='Enter operator phone' required />
                    </Form.Field>
                    {/*<Form.Field>*/}
                    {/*    <label>Car Document</label>*/}
                    {/*    <Input*/}
                    {/*        type='file'*/}
                    {/*        onChange={(e: ChangeEvent<HTMLInputElement>) => setCarDocument(e.target.files ? e.target.files[0] : null)}*/}
                    {/*    />*/}
                    {/*</Form.Field>*/}
                    <Form.Field>
                        <label>Description</label>
                        <TextArea value={descriptionInput.value} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => descriptionInput.onChange(e as any)} placeholder='Enter description' />
                    </Form.Field>

                    <Header>Offer</Header>
                    <Divider />
                    <OfferBuilder
                        offerPositions={offerPositions}
                        setOfferPositions={setOfferPositions}
                    />
                    <Divider />

                    <Button type='submit' primary>
                        Submit
                    </Button>
                    <Button type='button' onClick={handleClear} secondary>
                        Clear
                    </Button>
                </Form>
            </Segment>
            <RequestCreationConfirmModal
                closeModal={closeCreateRequestModal}
                dimmer={'blurring'}
                open={createRequestModalOpened}
                requests={[createdRequest]}
            />
        </div>
    );
};

const RequestCreateProviderHOC: FC<IRequestCreatePage> = memo((props: IRequestCreatePage) => {
    return (
        <UserProvider>
            <RequestProvider>
                <CreateRequestProvider>
                    <OfferProvider>
                        <RequestCreatePage {...props} />
                    </OfferProvider>
                </CreateRequestProvider>
            </RequestProvider>
        </UserProvider>
    );
});

export default RequestCreateProviderHOC;
