import { useApi } from '../useApi';
import { prepareAuthHeaders } from '../requestsHelper';
import { baseUrl, ServiceName } from '../urlHelper';

import { CONSTANTS_PATH } from '../constant';
import AuthData from '../../security/type/AuthData';
import { ApiResponse } from '../type/ApiResponse';
import { PaginatedResponse } from '../type/PaginatedResponse';
import { ChatRequest } from '../../type/request/ChatRequest';


export const useGetRequestListAPI = () => {
    const { apiCall } = useApi();

    const getRequestList = async (auth: AuthData | undefined, page: number = 0, size: number = 50): Promise<ApiResponse<PaginatedResponse<ChatRequest>>> => {
        const url: string = `${baseUrl(ServiceName.REQUESTS)}${CONSTANTS_PATH.REQUEST_SERVICE}`;

        return await apiCall<ApiResponse<PaginatedResponse<ChatRequest>>>(url, {
            method: 'GET',
            headers: {
                ...prepareAuthHeaders(auth),
            },
            params: { page, size },
        });
    };

    return { getRequestList };
};
