import UserRole from '../type/enum/UserRole';
import { Action, permissions } from '../permissions/rolePerimissionsUtil';
import { useSecurityContext } from './useSecurityContext';

const usePermission = () => {
    const { auth, user } = useSecurityContext();

    const userIsAuthenticated = (): boolean => {
        return (
            !!auth?.token &&
            !!auth?.phone &&
            !!auth?.smsCode &&
            !!user?.id &&
            !!user?.role
        );
    };

    const hasPermission = (
        resource: keyof typeof permissions,
        action: Action
    ): boolean => {
        const rolePermissions = permissions[user?.role || UserRole.ROLE_CLIENT];
        return (
            rolePermissions &&
            rolePermissions[resource] &&
            rolePermissions[resource].includes(action)
        );
    };

    return { userIsAuthenticated, hasPermission };
};

export default usePermission;
