import {ChangeEvent, FC} from "react";
import {Button, Dropdown, Form, Input} from "semantic-ui-react";
import {CountryPhoneCode} from "../../shared/util/phoneUtil";

interface LoginFormProps {
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    inputProps: { value: any; onChange: (e: ChangeEvent<HTMLInputElement>) => void; };
    verificationCodeVisible: boolean;
    countryPhoneCodes: CountryPhoneCode[];
    selectedPrefix: string;
    onPrefixChange: (value: string) => void;
}

const LoginForm: FC<LoginFormProps> = ({
                                           onSubmit,
                                           inputProps,
                                           verificationCodeVisible,
                                           countryPhoneCodes,
                                           selectedPrefix,
                                           onPrefixChange,
                                       }) => {
    const inputParams = verificationCodeVisible
        ? {
            fluid: true,
            placeholder: "Enter Verification Code",
            icon: "check",
        }
        : {
            fluid: true,
            placeholder: "Enter Phone Number",
        };
    const buttonText = verificationCodeVisible ? "Verify SMS-Code" : "Send";

    const handlePrefixChange = (_e: any, { value }: any) => {
        onPrefixChange(value);
    };

    const InputElement = (
        <Input
            {...inputParams}
            {...inputProps}
            className="iauto-login-input"
            labelPosition="left"
            label={
                !verificationCodeVisible && (
                    <Dropdown
                        options={countryPhoneCodes}
                        value={selectedPrefix}
                        onChange={handlePrefixChange}
                    />
                )
            }
        />
    );

    const SubmitButton = (
        <Button type="submit" secondary fluid size="large">
            {buttonText}
        </Button>
    );

    return (
        <Form size="large" onSubmit={onSubmit}>
            {InputElement}
            {SubmitButton}
        </Form>
    );
};

export default LoginForm;