import React, {PropsWithChildren} from "react";

import {Grid, GridRow} from "semantic-ui-react";
import Header from "../header/Header";
import LeftMenu from "../leftmenu/LeftMenu";

import './layout.css';

const Layout: React.FC<PropsWithChildren> = ({children}) => {

    return (
        <Grid stackable divided>
            <GridRow className='iauto-layout-header'>
                <Grid.Column width={16}>
                    <Header/>
                </Grid.Column>
            </GridRow>
            <GridRow className='iauto-layout-content'>
                <Grid.Column
                    computer={2}
                    tablet={4}
                    mobile={16}
                >
                    <LeftMenu/>
                </Grid.Column>
                <Grid.Column
                    computer={14}
                    tablet={12}
                    mobile={16}
                >
                    {children}
                </Grid.Column>
            </GridRow>
        </Grid>
    );
};


export default Layout;
