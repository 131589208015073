import { useApi } from '../useApi';
import {  prepareAuthHeaders } from '../requestsHelper';
import { baseUrl, ServiceName } from '../urlHelper';

import { CONSTANTS_PATH } from '../constant';
import AuthData from '../../security/type/AuthData';
import User from '../../security/type/User';
import { PaginatedResponse } from '../type/PaginatedResponse';


export const useGetUserListAPI = () => {
    const { apiCall } = useApi();

    const getUserList = async (
        auth: AuthData | undefined,
        page: number = 0,
        size: number = 50
    ): Promise<PaginatedResponse<User>> => {
        const url: string = `${baseUrl(ServiceName.USERS)}${CONSTANTS_PATH.USER_SERVICE}`;

        return await apiCall<PaginatedResponse<User>>(url, {
            method: 'GET',
            headers: {
                ...prepareAuthHeaders(auth),
            },
            params: { page, size },
        });
    };

    return { getUserList };
};
