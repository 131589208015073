import {useContext} from "react";
import {IRequestContext, RequestContext} from "../context/requestContext";


export const useRequestContext = (): IRequestContext => {
    const value: IRequestContext | undefined  = useContext(RequestContext);

    if (!value) {
        throw new Error('Wrap inside Request Context')
    }

    return value;
}