import {FC} from 'react';
import {useNotificationContext} from "../../shared/hook/useNotificationContext";

import {Message} from 'semantic-ui-react';


const notificationStylesType: Record<string, string> = {
    error: 'negative',
    success: 'positive',
    warning: 'warning',
    info: 'info',
};

const Notification: FC = () => {
    const {notification, clearNotification} = useNotificationContext();

    if (!notification) {
        return null;
    }

    return (
        <Message
            className={notificationStylesType[notification.type]}
            onDismiss={clearNotification}
            style={{position: 'fixed', top: '1rem', right: '1rem', zIndex: 1000, minWidth: '5%'}}
        >
            <Message.Header>
                {notification.type.toUpperCase()}
            </Message.Header>
            <p>
                {notification.message}
            </p>
        </Message>
    );
};

export default Notification;
