import {useContext} from "react";
import {CreateRequestContext, ICreateRequestContext} from "../context/createRequestContext";


export const useCreateRequestContext = (): ICreateRequestContext => {
    const value: ICreateRequestContext | undefined = useContext(CreateRequestContext);

    if (!value) {
        throw new Error('Wrap inside Create Request Context')
    }

    return value;
}