import {FC, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useSecurityContext} from "../../security/hook/useSecurityContext";

import {makeRandomKey} from "../../shared/util/Utilities";
import {arrayIsEmpty} from "../../shared/util/arrayUtils";

import {Icon, Menu} from "semantic-ui-react";
import {leftMenuItems, MenuItem} from "./menuItems";

import './leftmenu.css';


type LeftMenuProps = {};

const LeftMenu: FC<LeftMenuProps> = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { user } = useSecurityContext();

    const [activeItem, setActiveItem] = useState<string | null>(null);
    const [isRequestsSubmenuOpen, setIsRequestsSubmenuOpen] = useState(false);
    const [isUsersSubmenuOpen, setIsUsersSubmenuOpen] = useState(false); // New state for Users submenu

    const emptyMenuTitle = <h3>You don't have permissions for this menu</h3>;

    const filteredItemsOnPermissions: MenuItem[] | undefined = leftMenuItems?.filter((item: MenuItem) =>
        item.rolesHaveAccess.includes(user?.role as string)
    );

    const handleMenuItemClick = (route: string): void => {
        const urlSegments = location.pathname.split('/');
        urlSegments.pop();
        const newUrl: string = urlSegments.join('/') + route;

        navigate(newUrl);
        setActiveItem(route);
    };

    const menuItems = arrayIsEmpty(filteredItemsOnPermissions) ? (
        <Menu.Item key={makeRandomKey()}>
            {emptyMenuTitle}
        </Menu.Item>
    ) : (
        filteredItemsOnPermissions?.map((item: MenuItem) => {
            if (item.label === "Requests") {
                return (
                    <Menu.Item key={makeRandomKey()}>
                        <Menu.Header onClick={() => setIsRequestsSubmenuOpen(!isRequestsSubmenuOpen)}>
                            <Icon name={isRequestsSubmenuOpen ? "angle down" : "angle right"} />
                            {item.label}
                        </Menu.Header>
                        {isRequestsSubmenuOpen && (
                            <Menu.Menu>
                                <Menu.Item
                                    key={makeRandomKey()}
                                    active={activeItem === "/create-request"}
                                    onClick={() => handleMenuItemClick("/create-request")}
                                >
                                    Create Request
                                </Menu.Item>
                                <Menu.Item
                                    key={makeRandomKey()}
                                    active={activeItem === "/requests"}
                                    onClick={() => handleMenuItemClick("/requests")}
                                >
                                    View Requests
                                </Menu.Item>
                            </Menu.Menu>
                        )}
                    </Menu.Item>
                );
            }
            else if (item.label === "Users") {
                return (
                    <Menu.Item key={makeRandomKey()}>
                        <Menu.Header onClick={() => setIsUsersSubmenuOpen(!isUsersSubmenuOpen)}>
                            <Icon name={isUsersSubmenuOpen ? "angle down" : "angle right"} />
                            {item.label}
                        </Menu.Header>
                        {isUsersSubmenuOpen && (
                            <Menu.Menu>
                                <Menu.Item
                                    key={makeRandomKey()}
                                    active={activeItem === "/create-user"}
                                    onClick={() => handleMenuItemClick("/create-user")}
                                >
                                    Create User
                                </Menu.Item>
                                <Menu.Item
                                    key={makeRandomKey()}
                                    active={activeItem === "/users"}
                                    onClick={() => handleMenuItemClick("/users")}
                                >
                                    View Users
                                </Menu.Item>
                            </Menu.Menu>
                        )}
                    </Menu.Item>
                );
            }
            // Render other menu items
            else {
                return (
                    <Menu.Item
                        key={makeRandomKey()}
                        active={activeItem === item.route}
                        onClick={() => handleMenuItemClick(item.route)}
                    >
                        {item.label}
                    </Menu.Item>
                );
            }
        })
    );

    return (
        <Menu vertical fluid>
            {menuItems}
        </Menu>
    );
};

export default LeftMenu;