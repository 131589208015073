import React, {createContext, ReactNode, useCallback, useMemo} from "react";
import {useSecurityContext} from "../../../security/hook/useSecurityContext";
import {ChatRequest} from "../../../type/request/ChatRequest";
import {useCreateRequestAPI} from "../../../api/request/useCreateRequestAPI";


export interface ICreateRequestContext {
    createRequest: (request: ChatRequest) => Promise<void>;
}

interface CreateRequestProviderProps {
    children: ReactNode;
}


export const CreateRequestContext: React.Context<any> = createContext<ICreateRequestContext | undefined>(undefined);


export const CreateRequestProvider = ({children}: CreateRequestProviderProps) => {
    const {auth} = useSecurityContext();
    const {createRequestAPI} = useCreateRequestAPI();

    const createRequest = useCallback(async (request: ChatRequest): Promise<void> => {
        await createRequestAPI(auth, request);
    }, [auth, createRequestAPI]);

    const value = useMemo(() => {
        return {
            createRequest,
        };
    }, [createRequest]);

    return (
        <CreateRequestContext.Provider value={value}>
            {children}
        </CreateRequestContext.Provider>
    );
};