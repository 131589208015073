import {useContext} from "react";
import {IOfferContext, OfferContext} from "../context/offerContext";


export const useOfferContext = (): IOfferContext => {
    const value: IOfferContext | undefined = useContext(OfferContext);

    if (!value) {
        throw new Error('Wrap inside Offer Context')
    }

    return value;
}