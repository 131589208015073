import { CONSTANTS_BASE } from './constant';

export enum ServiceName {
    USERS = 'users',
    REQUESTS = 'requests',
    IAUTO_FRONTEND_CLIENT = 'iauto-frontend-client',
    IAUTO_FRONTEND_ADMIN = 'iauto-frontend-admin',
}

export const DEV_URL_MAP = new Map<ServiceName, string>([
    [ServiceName.USERS, 'http://localhost:8083'],
    [ServiceName.REQUESTS, 'http://localhost:8082'],
    [ServiceName.IAUTO_FRONTEND_CLIENT, 'http://localhost:3000'],
    [ServiceName.IAUTO_FRONTEND_ADMIN, 'http://localhost:3001'],
]);

export const QA_URL_MAP = new Map<ServiceName, string>([]);

export const PROD_URL_MAP = new Map<ServiceName, string>([
    [ServiceName.USERS, 'https://usr.iauto.md'],
    [ServiceName.REQUESTS, 'https://req.iauto.md'],
    [ServiceName.IAUTO_FRONTEND_CLIENT, 'https://iauto.md'],
    [ServiceName.IAUTO_FRONTEND_ADMIN, 'https://adm.iauto.md'],
]);

/**
 * General method to get app url
 * @param service
 */
export const baseUrl = (service: ServiceName): string => {
    const urlMap = getUrlMap();
    const serviceUrl = urlMap.get(service);

    if (!serviceUrl) {
        throw new Error(`Base URL for service "${service}" is not defined in the current environment`);
    }

    return `${serviceUrl}${CONSTANTS_BASE.API}${CONSTANTS_BASE.VERSION}`;
};

/**
 * Method to get proper url map
 */
export const getUrlMap = (): Map<string, string> => {
    const env = process.env.NODE_ENV;

    switch (env) {
        case 'development':
            return DEV_URL_MAP;
        case 'qa':
            return QA_URL_MAP;
        case 'production':
            return PROD_URL_MAP;
        default:
            throw new Error(`Unknown environment: ${env}`);
    }
};
