import React, { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { useSecurityContext } from '../../../security/hook/useSecurityContext';
import { useCreateRequestAPI } from '../../../api/request/useCreateRequestAPI';

import { ChatRequest } from '../../../type/request/ChatRequest';

export interface ICreateRequestContext {
    createRequest: (request: ChatRequest) => Promise<void>;
    createdRequest: ChatRequest;
    setCreatedRequests: () => void;
}

interface CreateRequestProviderProps {
    children: ReactNode;
}

export const CreateRequestContext: React.Context<any> = createContext<ICreateRequestContext | undefined>(undefined);

export const CreateRequestProvider = ({ children }: CreateRequestProviderProps) => {
    const { auth, user } = useSecurityContext();
    const [createdRequest, setCreatedRequests] = useState();
    const { createRequestAPI } = useCreateRequestAPI();

    const createRequest = useCallback(
        async (request: ChatRequest): Promise<void> => {
            const newRequest = await createRequestAPI(auth, user, request);
            setCreatedRequests(newRequest);
        },
        [auth, user, createRequestAPI]
    );

    const value = useMemo(() => {
        return {
            createRequest,
            createdRequest,
            setCreatedRequests,
        };
    }, [createRequest, createdRequest, setCreatedRequests]);

    return <CreateRequestContext.Provider value={value}>{children}</CreateRequestContext.Provider>;
};
