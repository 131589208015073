import { ChatRequest } from '../../../type/request/ChatRequest';
import { Pageable } from '../../../api/type/Pageable';
import { RequestType } from '../../../type/request/enum/RequestType';

export const requestsTableName: string = 'Requests';

export const requestsTableHeaderNames = {
    clientName: 'Client Name',
    clientPhoneNumber: 'User Phone',
    operatorName: 'Operator Name',
    operatorPhoneNumber: 'Operator Phone',
    status: 'Request Status',
    actions: 'Actions',
};

export const prepareTableData = (requests: Pageable<ChatRequest> | undefined) => {
    const tableCellsData: any[] = [];

    if (!requests) {
        return tableCellsData;
    }

    requests?.items?.forEach((request: ChatRequest): void => {
        tableCellsData.push({
            id: request.id,
            clientName: request.type === RequestType.ANONYMOUS ? 'PROMO - Client Not accepted' : `${request.client?.firstName || ''} ${request.client?.lastName || ''}`,
            clientPhoneNumber: request.type === RequestType.ANONYMOUS ? 'PROMO - No phone number' : request.client?.phoneNumber,
            operatorName: request.operator ? `${request.operator?.firstName || ''} ${request.operator?.lastName || ''}` : 'No data.Not in work',
            operatorPhoneNumber: request.operator ? request.operator?.phoneNumber : 'No data.Not in work',
            status: request.status,
        });
    });

    return tableCellsData;
};
