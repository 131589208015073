import React, { FC, memo, useEffect, useState } from 'react';
import { useUserContext } from '../hook/useUserContext';
import { useInput } from '../../../shared/hook/useInput';

import { Button, Form, Modal } from 'semantic-ui-react';
import User from '../../../security/type/User';
import UserRole from '../../../security/type/enum/UserRole';

type EditModalProps = {
    editModalClose: () => void;
    dimmer: string | undefined;
    open: boolean;
};

const UserEditModal: FC<EditModalProps> = (props: EditModalProps) => {
    const { dimmer, open, editModalClose } = props;
    // @ts-ignore
    const { userToEdit, updateSelectedUser } = useUserContext();

    const [updatableUserData, setUpdatableUserData] = useState<User>(userToEdit ?? {});
    const [firstNameProps, setFirstName] = useInput(userToEdit?.firstName ?? '');
    const [lastNameProps, setLastName] = useInput(userToEdit?.lastName ?? '');
    const [mailProps, setMail] = useInput(userToEdit?.mail ?? '');
    const [phoneNumberProps, setPhoneNumber] = useInput(userToEdit?.phoneNumber ?? '');
    const roleOptions = Object.values(UserRole).map((role) => ({
        key: role,
        text: role,
        value: role,
    }));

    useEffect(() => {
        if (userToEdit) {
            setUpdatableUserData(userToEdit);
            setFirstName(userToEdit.firstName ?? '');
            setLastName(userToEdit.lastName ?? '');
            setMail(userToEdit.mail ?? '');
            setPhoneNumber(userToEdit.phoneNumber ?? '');
        }
    }, [userToEdit]);

    const onRoleChange = (_e: React.SyntheticEvent<HTMLElement, Event>, data: any): void => {
        const { value } = data;
        setUpdatableUserData((prevData) => ({
            ...prevData,
            role: value,
        }));
    };

    const handleSubmit = async (): Promise<void> => {
        try {
            const updatedUserData: User = {
                ...updatableUserData,
                firstName: firstNameProps.value,
                lastName: lastNameProps.value,
                mail: mailProps.value,
                phoneNumber: phoneNumberProps.value,
            };

            await updateSelectedUser(userToEdit.id, updatedUserData);

            editModalClose();
        } catch (error) {
            console.error('Failed to update user data', error);
        }
    };

    return (
        <div className='iauto-modal-wrapper'>
            <Modal dimmer={dimmer} open={open} onClose={editModalClose}>
                <Modal.Header>User edit</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Input fluid label='First name' placeholder='First name' name='firstName' {...firstNameProps} />
                            <Form.Input fluid label='Last name' placeholder='Last name' name='lastName' {...lastNameProps} />
                            <Form.Input fluid label='Mail' placeholder='Mail' name='mail' {...mailProps} />
                            <Form.Input fluid label='Phone' placeholder='Phone' name='phoneNumber' {...phoneNumberProps} />
                            <Form.Select fluid label='Role' options={roleOptions} placeholder='Role' value={updatableUserData?.role || ''} onChange={onRoleChange} />
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={editModalClose}>
                        Close
                    </Button>
                    <Button positive onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default memo(UserEditModal);
