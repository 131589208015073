import { useContext } from 'react';
import {
    NotificationContext,
    NotificationContextType,
} from '../context/notificationContext';

export const useNotificationContext = (): NotificationContextType => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('Wrap in ErrorContext!');
    }
    return context;
};
