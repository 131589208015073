import {ChangeEvent, FC, memo, useState} from "react";
import {useInput} from "../../shared/hook/useInput";

import {UserProvider} from "../UsersPage/userContext";
import {RequestProvider} from "../RequestsPage/context/requestContext";
import {OfferProvider} from "../RequestsPage/context/offerContext";

import {Button, Divider, Form, Header, Input, Segment, TextArea} from "semantic-ui-react";
import {OfferPosition} from "./type/OfferPosition";
import OfferBuilder from "./component/OfferBuilder";
import {useCreateRequestContext} from "./hook/useCreateRequestContext";
import {CreateRequestProvider} from "./context/createRequestContext";


interface IRequestCreatePage {
};

const RequestCreatePage: FC = () => {
    const {createRequest} = useCreateRequestContext();
    const [userPhonesInput, resetUserPhone] = useInput("");
    const [operatorPhoneInput, resetOperatorPhone] = useInput("");
    const [descriptionInput, resetDescription] = useInput("");
    const [carDocument, setCarDocument] = useState<File | null>(null);
    const [offerPositions, setOfferPositions] = useState<OfferPosition[]>([
        {
            productName: "",
            brand: "",
            description: "",
            price: 0,
            quantity: 1,
            deliveryTime: null,
            notes: "",
            productCode: "",
            supplier: "",
        },
    ]);

    const handleSubmit = async (): Promise<void> => {
        const clientPhones = userPhonesInput.value
            .split(",")
            .map((phone: string) => phone.trim());

        const newRequest: any = {
            clientPhones: clientPhones,
            operatorPhone: [operatorPhoneInput.value],
            messages: [
                {img: carDocument},
                {text: descriptionInput.value},
                {offer : {offerPositions: offerPositions}}
            ]
        };

        await createRequest(newRequest);
        handleClear();
    };

    const handleClear = (): void => {
        resetUserPhone("");
        resetOperatorPhone("");
        resetDescription("");
        setCarDocument(null);
        setOfferPositions([])
    };

    return (
        <div className="iauto-admin-page-wrapper">
            <Segment>
                <Header>New Request</Header>
                <Divider/>
                <Form onSubmit={handleSubmit}>
                    <Form.Field required>
                        <label>Client Phones</label>
                        <Input
                            {...userPhonesInput}
                            placeholder="Enter phone numbers separated by commas"
                            required
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>Operator Phone</label>
                        <Input
                            {...operatorPhoneInput}
                            placeholder="Enter operator phone"
                            required
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Car Document</label>
                        <Input
                            type="file"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setCarDocument(e.target.files ? e.target.files[0] : null)
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Description</label>
                        <TextArea
                            value={descriptionInput.value}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                descriptionInput.onChange(e as any)
                            }
                            placeholder="Enter description"
                        />
                    </Form.Field>

                    <Header>Offer</Header>
                    <Divider/>
                    <OfferBuilder
                        offerPositions={offerPositions}
                        setOfferPositions={setOfferPositions}
                    />
                    <Divider/>

                    <Button type="submit" primary>
                        Submit
                    </Button>
                    <Button type="button" onClick={handleClear} secondary>
                        Clear
                    </Button>
                </Form>
            </Segment>
        </div>
    );
};

const RequestCreateProviderHOC: FC<IRequestCreatePage> = memo((props: IRequestCreatePage) => {
    return (
        <UserProvider>
            <RequestProvider>
                <CreateRequestProvider>
                    <OfferProvider>
                        <RequestCreatePage {...props} />
                    </OfferProvider>
                </CreateRequestProvider>
            </RequestProvider>
        </UserProvider>
    )
})

export default RequestCreateProviderHOC;