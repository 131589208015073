import UserRole from '../type/enum/UserRole';
import { RolePermission } from './RolePermission';

export type Resource = 'users' | 'requests' | 'store' | 'offers';
export type Action = 'read' | 'write' | 'edit' | 'delete';

interface Permissions {
    [key: string]: Action[];
}

export const permissions: Record<UserRole, Permissions> = {
    ROLE_CLIENT: {
        users: [],
        requests: [],
        offers: [],
        store: [],
    },
    ROLE_OPERATOR: {
        users: [],
        requests: [],
        offers: [],
        store: [],
    },
    ROLE_ADMIN: {
        users: [
            RolePermission.READ,
            RolePermission.WRITE,
            RolePermission.EDIT,
            RolePermission.DELETE,
        ],
        requests: [
            RolePermission.READ,
            RolePermission.WRITE,
            RolePermission.EDIT,
            RolePermission.DELETE,
        ],
        offers: [
            RolePermission.READ,
            RolePermission.WRITE,
            RolePermission.EDIT,
            RolePermission.DELETE,
        ],
        store: [
            RolePermission.READ,
            RolePermission.WRITE,
            RolePermission.EDIT,
            RolePermission.DELETE,
        ],
    },
    ROLE_OPERATOR_SUPERVISOR: {
        users: [RolePermission.READ],
        requests: [
            RolePermission.READ,
            RolePermission.WRITE,
            RolePermission.EDIT,
        ],
        offers: [
            RolePermission.READ,
            RolePermission.WRITE,
            RolePermission.EDIT,
            RolePermission.DELETE,
        ],
        store: [RolePermission.READ],
    },
    ROLE_STOREKEEPER: {
        users: [],
        requests: [RolePermission.READ],
        offers: [RolePermission.READ],
        store: [
            RolePermission.READ,
            RolePermission.WRITE,
            RolePermission.DELETE,
        ],
    },
};
