import {  prepareAuthHeaders } from '../requestsHelper';
import { useApi } from '../useApi';
import { baseUrl, ServiceName } from '../urlHelper';

import AuthData from '../../security/type/AuthData';
import { CONSTANTS_PATH } from '../constant';


export const useUpdateOfferStatusAPI = () => {
    const { apiCall } = useApi();

    const updateOfferStatus = async (auth: AuthData | undefined, statusData: object): Promise<any> => {
        const url: string = `${baseUrl(ServiceName.REQUESTS)}${CONSTANTS_PATH.OFFERS}/batchUpdate`;

        return await apiCall<any>(
            url,
            {
                method: 'PUT',
                headers: {
                    ...prepareAuthHeaders(auth),
                },
                data: statusData,
            },
            'Offer status changed successfully!'
        );
    };

    return { updateOfferStatus };
};
