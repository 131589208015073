import {ChangeEvent, FC} from "react";
import {OfferPosition} from "../type/OfferPosition";
import {Button, Form, Input, Segment, TextArea} from "semantic-ui-react";


interface OfferBuilderProps {
    offerPositions: OfferPosition[];
    setOfferPositions: React.Dispatch<React.SetStateAction<OfferPosition[]>>;
}

const OfferBuilder: FC<OfferBuilderProps> = ({offerPositions, setOfferPositions}) => {
    const addOfferPosition = () => {
        setOfferPositions([
            ...offerPositions,
            {
                productName: "",
                brand: "",
                description: "",
                price: 0,
                quantity: 1,
                deliveryTime: null,
                notes: "",
                productCode: "",
                supplier: "",
            },
        ]);
    };

    const handleOfferChange = (index: number, field: string, value: any, e?: any): void => {
        const updatedOffers = [...offerPositions];

        if (field === "price") {
            const allowedKeys = ["ArrowLeft", "ArrowRight", "Delete", "Tab"];
            const regex = /^[0-9]*\.?[0-9]*$/;

            if (allowedKeys.includes(e?.key) || regex.test(value) || value === '.') {
                updatedOffers[index] = { ...updatedOffers[index], [field]: value };
            } else {
                return;
            }
        } else {
            updatedOffers[index] = { ...updatedOffers[index], [field]: value };
        }

        setOfferPositions(updatedOffers);
    };

    return (
        <>
            {offerPositions.map((offer, index) => (
                <Segment key={index}>
                    <Form.Group widths="equal">
                        <Form.Field required>
                            <label>Product Name</label>
                            <Input
                                value={offer.productName}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleOfferChange(index, "productName", e.target.value)
                                }
                                placeholder="Product Name"
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Brand</label>
                            <Input
                                value={offer.brand}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleOfferChange(index, "brand", e.target.value)
                                }
                                placeholder="Brand"
                            />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field required>
                            <label>Description</label>
                            <Input
                                value={offer.description}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleOfferChange(index, "description", e.target.value)
                                }
                                placeholder="Description"
                                required
                            />
                        </Form.Field>

                        <Form.Field required>
                            <label>Price</label>
                            <Input
                                value={offer.price}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleOfferChange(index, "price", Number(e.target.value), e.nativeEvent)
                                }
                                placeholder="Price"
                                required
                            />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field required>
                            <label>Quantity</label>
                            <Input
                                type="number"
                                value={offer.quantity}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleOfferChange(index, "quantity", Number(e.target.value))
                                }
                                placeholder="Quantity"
                                min="1"
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Delivery Time</label>
                            {/* DatePicker can be placed here */}
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Product Code</label>
                            <Input
                                value={offer.productCode}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleOfferChange(index, "productCode", e.target.value)
                                }
                                placeholder="Product Code"
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Supplier</label>
                            <Input
                                value={offer.supplier}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleOfferChange(index, "supplier", e.target.value)
                                }
                                placeholder="Supplier"
                            />
                        </Form.Field>
                    </Form.Group>

                    <Form.Field>
                        <label>Notes</label>
                        <TextArea
                            value={offer.notes}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                                handleOfferChange(index, "notes", e.target.value)
                            }
                            placeholder="Notes"
                        />
                    </Form.Field>
                </Segment>
            ))}

            <Button type="button" onClick={addOfferPosition}>
                Add Position
            </Button>
        </>
    );
};


export default OfferBuilder;