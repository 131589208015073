import React from "react";

type BaseLayoutProps = {
    children: React.ReactNode;
};


const BaseLayout: React.FC<BaseLayoutProps> = (props: BaseLayoutProps) => {
    return (
        <div className='iauto-layout-wrapper'>
            {props.children}
        </div>
    );
};

export default BaseLayout;