import React, { FC, memo, useCallback, useEffect, useReducer, useState } from 'react';
import { DEV_URL_MAP, PROD_URL_MAP, ServiceName } from '../../api/urlHelper';
import { useNotificationContext } from '../../shared/hook/useNotificationContext';

import { UserProvider } from '../UsersPage/userContext';
import { RequestProvider } from './context/requestContext';
import { modalReducer } from '../../shared/reducer/modalReducer';
import { OfferProvider } from './context/offerContext';
import { useOfferContext } from './hook/useOfferContext';
import { useRequestContext } from './hook/useRequestContext';

import DataTable from '../../common/table/DataTable';
import RequestEditModal from '../RequestsPage/component/RequestEditModal';
import { Pagination, Segment } from 'semantic-ui-react';

import { ChatRequest } from '../../type/request/ChatRequest';
import { TableAction } from '../../common/table/type/TableAction';
import { isDevelopment } from '../../shared/util/Utilities';

interface IRequestPage {}

const RequestsPage: FC<IRequestPage> = () => {
    const { requests, getRequests } = useRequestContext();
    const { getRequestOffers } = useOfferContext();
    const [requestIdForEdit, setRequestIdForEdit] = useState<string>('');
    const [state, dispatch] = useReducer(modalReducer, {
        open: false,
        dimmer: undefined,
    });
    const { setNotification } = useNotificationContext();

    const { open, dimmer } = state;

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize] = useState<number>(50);

    const tableName: string = 'Requests';
    const tableHeaderNames = {
        clientName: 'Client Name',
        clientPhoneNumber: 'User Phone',
        operatorName: 'Operator Name',
        operatorPhoneNumber: 'Operator Phone',
        status: 'Request Status',
        actions: 'Actions',
    };
    const getTableData = () => {
        const tableCellsData: any[] = [];

        requests?.items?.forEach((request: ChatRequest): void => {
            tableCellsData.push({
                id: request.id,
                clientName: `${request.client?.firstName || ''} ${request.client?.lastName || ''}`,
                clientPhoneNumber: request.client?.phoneNumber,
                operatorName: request.operator ? `${request.operator?.firstName || ''} ${request.operator?.lastName || ''}` : 'No data.Not in work',
                operatorPhoneNumber: request.operator ? request.operator?.phoneNumber : 'No data.Not in work',
                status: request.status,
            });
        });

        return tableCellsData;
    };

    const fetchRequests = useCallback(async () => {
        await getRequests(currentPage, pageSize);
    }, [getRequests, currentPage, pageSize]);

    useEffect(() => {
        fetchRequests();
    }, [fetchRequests]);

    const handlePageChange = (_event: React.MouseEvent<HTMLAnchorElement>, data: any): any => {
        const newActivePage = Number(data.activePage);

        if (!isNaN(newActivePage)) {
            setCurrentPage(newActivePage - 1);
        }
    };

    const onEditActionClick = async (requestId: string): Promise<void> => {
        await getRequestOffers(requestId);
        setRequestIdForEdit(requestId);
        dispatch({ type: 'OPEN_EDIT_MODAL', dimmer: 'blurring' });
    };
    const editModalClose = (): void => {
        dispatch({ type: 'CLOSE_EDIT_MODAL' });
    };

    const onCopyLink = (requestId: string): void => {
        try {
            const url: string | undefined = isDevelopment ?
                DEV_URL_MAP.get(ServiceName.IAUTO_CLIENT) : PROD_URL_MAP.get(ServiceName.IAUTO_CLIENT);

            const link: string = `${url}/requests/${requestId}`;

            navigator.clipboard.writeText(link).then((): void => {
                setNotification({ type: 'success', message: 'Request URL copied successfully!' }, 2000);
            });
        } catch (error) {
            console.error('Error generating copy link:', error);
        }
    };

    const rowActions: TableAction<any>[] = [
        {
            label: 'Edit',
            color: 'blue',
            icon: 'edit',
            onClick: onEditActionClick,
        },
        {
            label: 'Link',
            color: 'purple',
            icon: 'copy',
            onClick: onCopyLink,
        },
    ];

    return (
        <div className='iauto-admin-page-wrapper'>
            <Segment>
                <DataTable tableCellsData={getTableData()} tableHeaderNames={tableHeaderNames} tableName={tableName} actions={rowActions} />
                <Pagination activePage={currentPage + 1} totalPages={requests?.totalPages || 0} onPageChange={handlePageChange} siblingRange={1} boundaryRange={1} />
            </Segment>
            <RequestEditModal editModalClose={editModalClose} open={open} dimmer={dimmer} requestId={requestIdForEdit} />
        </div>
    );
};

const RequestsProviderHOC: FC<IRequestPage> = memo((props: IRequestPage) => {
    return (
        <UserProvider>
            <RequestProvider>
                <OfferProvider>
                    <RequestsPage {...props} />
                </OfferProvider>
            </RequestProvider>
        </UserProvider>
    );
});

export default RequestsProviderHOC;
