import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { useGetRequestListAPI } from '../../../api/request/useGetRequestListAPI';
import { useSecurityContext } from '../../../security/hook/useSecurityContext';
import { mapResponseToPageable } from '../../../api/util/mapper';

import { Pageable } from '../../../api/type/Pageable';
import { ChatRequest } from '../../../type/request/ChatRequest';
import { PaginatedResponse } from '../../../api/type/PaginatedResponse';


export interface IRequestContext {
    requests: Pageable<ChatRequest> | undefined;
    getRequests: (page?: number, size?: number) => Promise<void>;
    requestToEdit: ChatRequest | {};
}

interface CreateRequestContextProps {
    children: ReactNode;
}

export const RequestContext = createContext<IRequestContext | undefined>(undefined);

export const RequestProvider = ({ children }: CreateRequestContextProps) => {
    const { auth } = useSecurityContext();
    const { getRequestList } = useGetRequestListAPI();
    const [requests, setRequests] = useState<Pageable<ChatRequest> | {}>({});
    const [requestToEdit] = useState<ChatRequest | {}>({});

    /**
     * @GET
     * Get requests
     */
    const getRequests = useCallback(
        async (page: number = 0, size: number = 50): Promise<void> => {
            try {
                const response: PaginatedResponse<ChatRequest> = await getRequestList(auth, page, size);
                const pageableRequests: Pageable<ChatRequest> = mapResponseToPageable(response);

                if (!requests || !Array.isArray(pageableRequests.items)) {
                    throw new Error('Wrong get requests response format');
                }

                setRequests(pageableRequests);
            } catch (error) {
                console.error('Error fetching requests data:', error);
            }
        },
        [auth]
    );

    const value = useMemo(() => {
        return {
            requests,
            getRequests,
            requestToEdit,
        };
    }, [requests, getRequests, requestToEdit]);

    return (
        // @ts-ignore
        <RequestContext.Provider value={value}>{children}</RequestContext.Provider>
    );
};