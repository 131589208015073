import { useApi } from '../useApi';
import {  prepareAuthHeaders } from '../requestsHelper';
import { baseUrl, ServiceName } from '../urlHelper';

import { CONSTANTS_PATH } from '../constant';
import AuthData from '../../security/type/AuthData';


export const useGetUserListAPI = () => {
    const { apiCall } = useApi();

    const getUserList = async (auth: AuthData | undefined): Promise<any[]> => {
        const url: string = `${baseUrl(ServiceName.USERS)}${CONSTANTS_PATH.USER_SERVICE}`;

        return await apiCall<any[]>(
            url,
            {
                method: 'GET',
                headers: {
                    ...prepareAuthHeaders(auth),
                },
            },
            ''
        );
    };

    return { getUserList };
};
