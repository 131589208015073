import {FormState} from "../type/FormState";
import {ActionType} from "../type/ActionType";
import UserRole from "../../../security/type/enum/UserRole";


export const initialState: FormState = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    mail: '',
    password: '',
    role: UserRole.ROLE_CLIENT,
    language: 'RO'
};

export enum FormReducerActions {
    SET_FIELD = 'SET_FIELD',
    RESET_FORM = 'RESET_FORM'
}

export const formReducer = (state: FormState, action: ActionType): FormState => {
    switch (action.type) {
        case FormReducerActions.SET_FIELD:
            return {
                ...state,
                [action.field]: action.value
            };
        case FormReducerActions.RESET_FORM:
            return initialState;
        default:
            return state;
    }
};