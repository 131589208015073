import { FC, useEffect, useState } from 'react';
import { useNotificationContext } from '../../../shared/hook/useNotificationContext';
import { isDevelopment } from '../../../shared/util/Utilities';
import { DEV_URL_MAP, PROD_URL_MAP, ServiceName } from '../../../api/urlHelper';

import { Button, Icon, Label, Modal } from 'semantic-ui-react';
import { ChatRequest } from '../../../type/request/ChatRequest';

type RequestCreationConfirmModalProps = {
    closeModal: () => void;
    dimmer: string | undefined;
    open: boolean;
    requests: ChatRequest[];
};


const RequestCreationConfirmModal: FC<RequestCreationConfirmModalProps> = ({ dimmer, open, closeModal, requests }) => {
    const { setNotification } = useNotificationContext();
    const [requestLink, setRequestLink] = useState<string>('');

    useEffect(() => {
        const request = requests[0];
        if (!request) return;

        const { id: requestId, client } = request;
        const url: string | undefined = isDevelopment
            ? DEV_URL_MAP.get(ServiceName.IAUTO_FRONTEND_CLIENT)
            : PROD_URL_MAP.get(ServiceName.IAUTO_FRONTEND_CLIENT);

        if (requestId && url) {
            const link = client
                ? `${url}/requests/${requestId}`
                : `${url}/promo/requests/${requestId}`;
            setRequestLink(link);
        }
    }, [requests]);

    const onCopyLink = (): void => {
        try {
            navigator.clipboard.writeText(requestLink).then((): void => {
                setNotification({ type: 'success', message: 'Request URL copied successfully!' }, 2000);
            });
        } catch (error) {
            console.error('Error generating copy link:', error);
        }
    };

    return (
        <div className="iauto-modal-wrapper">
            <Modal
                dimmer={dimmer}
                open={open}
                onClose={closeModal}
            >
                <Modal.Header>Request created</Modal.Header>
                <Modal.Content>
                    <Button as="div" labelPosition="left">
                        <Label as="a" basic pointing="right">
                            {requestLink || 'Loading...'}
                        </Label>
                        <Button icon onClick={onCopyLink} disabled={!requestLink}>
                            <Icon name="copy" />
                            Copy
                        </Button>
                    </Button>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default RequestCreationConfirmModal;
