import { prepareAuthHeaders } from '../requestsHelper';
import { useApi } from '../useApi';
import { baseUrl, ServiceName } from '../urlHelper';

import AuthData from '../../security/type/AuthData';
import { CONSTANTS_PATH } from '../constant';


export const useGetOfferListAPI = () => {
    const { apiCall } = useApi();

    const getOfferList = async (auth: AuthData | undefined, requestId: string): Promise<any> => {
        const url: string = `${baseUrl(ServiceName.REQUESTS)}${CONSTANTS_PATH.OFFERS}/request/${requestId}`;

        return await apiCall<any>(url, {
            method: 'GET',
            headers: {
                ...prepareAuthHeaders(auth),
            },
        });
    };

    return { getOfferList };
};
