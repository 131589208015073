import { useApi } from '../useApi';
import AuthData from '../../security/type/AuthData';
import { baseUrl, ServiceName } from '../urlHelper';
import { CONSTANTS_PATH } from '../constant';
import { prepareAuthHeaders } from '../requestsHelper';

export const useDeleteUserAPI = () => {
    const { apiCall } = useApi();

    const deleteUser = async (userId: string, auth: AuthData | undefined): Promise<string> => {
        if (!auth) {
            throw new Error("Authentication data is required to delete a user.");
        }

        const url: string = `${baseUrl(ServiceName.USERS)}${CONSTANTS_PATH.USER_SERVICE}/${userId}`;

        return await apiCall<string>(
            url,
            {
                method: 'DELETE',
                headers: {
                    ...prepareAuthHeaders(auth),
                },
            },
            `User with id = ${userId} deleted successfully!`
        );
    };

    return { deleteUser };
};