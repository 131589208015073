export type CountryPhoneCode = {
    key: string;
    value: string;
    text: string;
};

export const countryPhoneCodes: CountryPhoneCode[] = [
    { value: '+7', text: '+7', key: '+7' },
    { value: '+40', text: '+40', key: '+40' },
    { value: '+380', text: '+380', key: '+380' },
    { value: '+49', text: '+49', key: '+49' },
    { value: '+33', text: '+33', key: '+33' },
    { value: '+373', text: '+373', key: '+373' },
    { value: '022', text: '022', key: '022' },
];
