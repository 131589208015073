import React, { createContext, FC, useState } from 'react';

type NotificationType = 'error' | 'success' | 'warning' | 'info';

type Notification = {
    type: NotificationType;
    message: string;
};

export type NotificationContextType = {
    notification: Notification | null;
    setNotification: (
        notification: Notification | null,
        interval: number
    ) => void;
    clearNotification: () => void;
};

export const NotificationContext = createContext<
    NotificationContextType | undefined
>(undefined);

export const NotificationProvider: FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [notification, setNotificationState] = useState<Notification | null>(
        null
    );

    const clearNotification = (): void => {
        setNotificationState(null);
    };

    const setNotification = (
        notification: Notification | null,
        autoDismiss = 3000
    ) => {
        setNotificationState(notification);

        if (notification && autoDismiss > 0) {
            setTimeout(() => {
                clearNotification();
            }, autoDismiss);
        }
    };

    return (
        <NotificationContext.Provider
            value={{ notification, setNotification, clearNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};
