import {FC} from "react";
import {Button, Container, Header, Icon} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";


const NotFound: FC = () => {
    const navigate = useNavigate();

    const goToMainPage = (): void => {
        navigate('/');
    };

    return (
        <Container textAlign="center" style={{padding: '5em 0'}}>
            <Icon name="exclamation triangle" size="huge" color="red"/>
            <Header as="h1" style={{fontSize: '4em'}}>404</Header>
            <Header as="h2" style={{fontSize: '2em'}}>Page Not Found</Header>
            <Button primary size="large" onClick={goToMainPage}>
                <Icon name="home"/>
                Go to Main Page
            </Button>
        </Container>
    );
};

export default NotFound;