import React, { FC, memo } from 'react';
import { UserProvider } from './userContext';
import { useUserContext } from './hook/useUserContext';
import { modalReducer } from '../../shared/reducer/modalReducer';

import DataTable from '../../common/table/DataTable';
import EditModal from './component/UserEditModal';
import { Segment } from 'semantic-ui-react';
import { TableAction } from '../../common/table/type/TableAction';

interface IUserPage extends Partial<Record<'a' | 'b', string>> {}

const UsersPage: FC<IUserPage> = () => {
    const { users, chooseUserForEdit } = useUserContext();
    const [state, dispatch] = React.useReducer(modalReducer, {
        open: false,
        dimmer: undefined,
    });
    const { open, dimmer } = state;
    const tableName: string = 'Users';
    const tableHeaderNames = {
        firstName: 'First Name',
        lastName: 'Last Name',
        mail: 'E-mail',
        phoneNumber: 'Phone',
        role: 'Role',
        createAt: 'Registered',
        actions: 'Actions',
    };

    const onEditActionClick = (userId: string): void => {
        chooseUserForEdit(userId);
        dispatch({ type: 'OPEN_EDIT_MODAL', dimmer: 'blurring' });
    };

    const editModalClose = (): void => {
        chooseUserForEdit();
        dispatch({ type: 'CLOSE_EDIT_MODAL' });
    };

    const rowActions: TableAction<any>[] = [
        {
            label: 'Edit',
            color: 'blue',
            icon: 'edit',
            onClick: onEditActionClick,
        },
    ];

    return (
        <div className='iauto-admin-page-wrapper'>
            <Segment>
                <DataTable tableCellsData={users}
                           tableHeaderNames={tableHeaderNames}
                           tableName={tableName}
                           actions={rowActions}
                />
            </Segment>
            <EditModal editModalClose={editModalClose} open={open} dimmer={dimmer} />
        </div>
    );
};

const UsersProviderHOC: FC<IUserPage> = memo((props: IUserPage) => {
    return (
        <UserProvider>
            <UsersPage {...props} />
        </UserProvider>
    );
});

export default UsersProviderHOC;
