import { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SecurityProvider } from '../../security/securityContext';
import { NotificationProvider } from '../../shared/context/notificationContext';

import PrivateRouteWrapper from './PrivateRouteWrapper';
import LoginRouteWrapper from './LoginRouteWrapper';

import Notification from '../../common/notification/Notification';
import Layout from '../../common/layout/Layout';
import BaseLayout from '../../common/layout/BaseLayout';
import UsersPage from '../../page/UsersPage/UsersPage';
import RequestsPage from '../../page/RequestsPage/RequestsPage';
import RequestCreatePage from '../../page/RequestsCreatePage/RequestCreatePage';
import NotFound from '../../page/NotFound/NotFound';
import MainPage from '../../page/MainPage/MainPage';
import StorePage from '../../page/StorePage/StorePage';
import LoginPage from '../../page/LoginPage/LoginPage';
import UserCreatePage from '../../page/UserCreatePage/UserCreatePage';

//TODO refactor-push route params to routes.ts
export const RouterService: FC = () => {
    return (
        <Router>
            <SecurityProvider>
                <NotificationProvider>
                    <Notification />
                    <Routes>
                        <Route element={<LoginRouteWrapper />}>
                            <Route
                                path='/login'
                                element={
                                    <BaseLayout>
                                        <LoginPage />
                                    </BaseLayout>
                                }
                            />
                        </Route>
                        <Route element={<PrivateRouteWrapper />}>
                            <Route
                                path='/'
                                element={
                                    <Layout>
                                        <MainPage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/users'
                                element={
                                    <Layout>
                                        <UsersPage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/requests'
                                element={
                                    <Layout>
                                        <RequestsPage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/create-user'
                                element={
                                    <Layout>
                                        <UserCreatePage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/create-request'
                                element={
                                    <Layout>
                                        <RequestCreatePage />
                                    </Layout>
                                }
                            />
                            <Route
                                path='/store'
                                element={
                                    <Layout>
                                        <StorePage />
                                    </Layout>
                                }
                            />
                        </Route>
                        <Route
                            path='*'
                            element={
                                <BaseLayout>
                                    <NotFound />
                                </BaseLayout>
                            }
                        />
                    </Routes>
                </NotificationProvider>
            </SecurityProvider>
        </Router>
    );
};
