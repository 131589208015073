import {FC} from "react";
import usePermission from "../../security/hook/usePermission";
import {Navigate, Outlet} from "react-router-dom";

interface LoginRouteWrapperProps {

}

const LoginRouteWrapper: FC<LoginRouteWrapperProps> = () => {
    const {userIsAuthenticated} = usePermission();

    if (userIsAuthenticated()) {
        return <Navigate to="/"/>;
    }

    return <Outlet/>;
};

export default LoginRouteWrapper;