export const modalReducer = (
    _state: any,
    action: any
) => {
    switch (action.type) {
        case 'OPEN_EDIT_MODAL':
            return {open: true, dimmer: action.dimmer};
        case 'CLOSE_EDIT_MODAL':
            return {open: false};
        default:
            return {open: false};
    }
};