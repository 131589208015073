export const isDevelopment: boolean = process.env.NODE_ENV === 'development';

/**
 * Method generates random string which can be used as react-element key
 * @returns key{string}
 */
export const makeRandomKey = (): string => {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < 100; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }

    return result;
};

export const getFormattedDate = (date: string): string => {
    return new Date(date).toLocaleDateString('fr-CA');
};

export const getCurrentYear = (): number => {
    return new Date().getFullYear();
};

export const getDeepCopyObject = (entity: any): any => {
    return JSON.parse(JSON.stringify(entity));
};

/**
 * Method to convert files to base64.
 * All validations on size and mime-type can be found in InputValidator
 * @param file
 */
export const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject): void => {
        const reader: FileReader = new FileReader();

        reader.onloadend = () => {
            resolve(reader.result as string);
        };

        reader.onerror = () => {
            reject(reader.error);
        };

        reader.readAsDataURL(file);
    });
};
