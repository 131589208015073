import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {useNotificationContext} from "../shared/hook/useNotificationContext";
import {isDevelopment} from "../shared/util/Utilities";
import {HTTP_CODE} from "./constant";


export const useApi = () => {
    const { setNotification } = useNotificationContext();

    const apiCall = async <T>(
        url: string,
        options: AxiosRequestConfig = {},
        successMessage?: string
    ): Promise<T> => {
        try {
            const { method = 'GET' } = options;
            const response: AxiosResponse<T> = await axios(url, options);

            if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase()) && successMessage) {
                setNotification({ type: 'success', message: successMessage }, 3000);
            }

            return response.data;
        } catch (error) {
            handleApiError(error);
            throw error;
        }
    };

    const handleApiError = (error: unknown): void => {
        let errorMessage = "An unknown error occurred";

        if (axios.isAxiosError(error)) {
            if (!error.response) {
                if (error.code === "ERR_NETWORK") {
                    errorMessage = "Unable to connect to the server. Check your internet connection, or try again later";
                }
            } else {
                errorMessage = error.response?.data?.message || error.message || errorMessage;

                if (error.response?.status === HTTP_CODE.AUTH_REQUIRED) {
                    setNotification({ type: "info", message: "Enter SMS code" }, 3000);
                    return;
                }
            }
        } else if (error instanceof Error) {
            errorMessage = error.message;
        }

        setNotification({ type: "error", message: errorMessage }, 3000);

        if (isDevelopment) {
            console.error("API Error:", error);
        }
    };

    return { apiCall };
};