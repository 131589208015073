import {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useGetUserListAPI } from '../../api/user/useGetUserListAPI';
import { useSecurityContext } from '../../security/hook/useSecurityContext';
import { useUpdateUserAPI } from '../../api/user/useUpdateUserAPI';
import { useCreateUserAPI } from '../../api/user/useCreateUserAPI';

import User from '../../security/type/User';

type UserType = Record<string, unknown>;

export interface IUserContext {
    users: Array<User> | [];
    userToEdit: User;
    chooseUserForEdit: (userId?: string) => void;
    changeLinkParams: (params: string[]) => void;
    getSelectedUser: (id: string) => UserType | undefined;
    createNewUser: (userData: any) => void;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);

// @ts-ignore
export const UserProvider = ({ children }) => {
    const { auth } = useSecurityContext();
    const { getUserList } = useGetUserListAPI();
    const { createUser } = useCreateUserAPI();
    const { updateUser } = useUpdateUserAPI();
    const [linkParams, setLinkParams] = useState<Array<string>>([]);
    const [users, setUser] = useState<Array<UserType>>([]);
    const [userToEdit, setUserToEdit] = useState<User | unknown>({});

    /**
     * GET getting users on user page load
     */
    useEffect((): void => {
        (async function (): Promise<void> {
            try {
                const data: Array<UserType> | null = await getUserList(auth);

                if (!data || !Array.isArray(data)) {
                    throw new Error(
                        '[ERROR] received user list is not an array'
                    );
                }

                setUser(data);
            } catch (e) {
                console.error('[ERROR] fetching user data:', e);
            }
        })();
    }, [linkParams, auth]);

    /**
     * POST create user
     */
    const createNewUser = useCallback(
        async (userData: User): Promise<void> => {
            try {
                await createUser(userData, auth);
            } catch (error) {
                console.error('Failed to create new user', error);
            }
        },
        [auth]
    );

    /**
     * PUT update for user by id in context
     */
    const updateSelectedUser = useCallback(
        async (userId: string, updatedUserData: User): Promise<void> => {
            try {
                const updatedUser = await updateUser(
                    userId,
                    updatedUserData,
                    auth
                );

                setUser((prevUsers) =>
                    prevUsers.map((user) =>
                        user.id === userId ? updatedUser : user
                    )
                );
            } catch (error) {
                console.error('Failed to update user data', error);
            }
        },
        [auth, updateUser]
    );

    const setUsers = useCallback(
        (users: any) => {
            return setUser(users);
        },
        [users]
    );

    const getSelectedUser = useCallback(
        (queryId: string) => {
            return users.find(({ id }) => id === queryId);
        },
        [users]
    );

    const chooseUserForEdit = useCallback(
        (userId?: string | undefined) => {
            if (!userId) {
                return setUserToEdit({});
            }

            const userToEdit = users.find(({ id }) => id === userId);

            return setUserToEdit(userToEdit);
        },
        [users]
    );

    const changeLinkParams = useCallback(
        (params: string[]) => {
            return setLinkParams(params);
        },
        [setLinkParams]
    );

    const value = useMemo(() => {
        return {
            users,
            userToEdit,
            setUsers,
            createNewUser,
            getSelectedUser,
            changeLinkParams,
            chooseUserForEdit,
            updateSelectedUser,
        };
    }, [
        users,
        userToEdit,
        setUsers,
        createNewUser,
        chooseUserForEdit,
        getSelectedUser,
        updateSelectedUser,
        changeLinkParams,
    ]);

    return (
        // @ts-ignore
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
};
