import { ChangeEvent, FC } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { OfferPositionRawImage } from '../type/entity/OfferPositionRawImage';

interface ImageUploadProps {
    offerPositionIndex: number;
    images: OfferPositionRawImage[];
    handleFileChange: (index: number, e: ChangeEvent<HTMLInputElement>) => void;
}

const ImageUpload: FC<ImageUploadProps> = ({ offerPositionIndex, images, handleFileChange }) => {
    return (
        <div>
            <Button as='label' htmlFor={`image-upload-${offerPositionIndex}`} icon='upload' content='Upload Image' />
            <input
                id={`image-upload-${offerPositionIndex}`}
                type='file'
                style={{ display: 'none' }}
                accept='image/jpeg,image/png,image/jpg,image/gif,image/svg,image/webp'
                onChange={(e) => handleFileChange(offerPositionIndex, e)}
            />
            {images.length > 0 && (
                <div style={{ marginTop: '10px' }}>
                    {images.map((img: OfferPositionRawImage, index: number) => (
                        <div key={index} style={{ marginBottom: '5px' }}>
                            <Icon name='file image' />
                            {img.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ImageUpload;
